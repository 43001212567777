.step-description {
  @include content-container;
  text-align: var(--text-align);
  padding: var(--large-container-padding);
  background-color: var(--description-bg-color);

  .title {
    text-transform: var(--step-description-case);
    font-size: var(--title-font-size);
    font-weight: var(--step-description-font-weight);
    font-family: var(--header-font);
  }

  .description {
    text-transform: none;
  }

  span {
    font-family: var(--body-font);
    font-weight: var(--header-font-weight);
  }

  p {
    margin: 5px 0 0;
    @include content-paragraph;
  }
}

.step-description-container {
  display: flex; /* Use flexbox to arrange items horizontally */
  align-items: center; /* Center items vertically within the container */
  background-color: var(--description-bg-color);
  //max-height: 200px;
}

//.step-description-container
// .img1 {
//   object-fit: cover; /* Maintain aspect ratio and cover the entire height */
//   height: 100%; /* Make the image fill the whole height of the container */
//   width: 100%; /* Maintain aspect ratio var tidigare auto */
//   max-width: 30%;
//   max-height: 205px;

//   content: var(--img-src-estate-data);
// }
.step-description-img-EstateData {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-estate-data-height);
  content: var(--img-src-estate-data);
}
.step-description-img-Preparations {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-preparations-height);
  content: var(--img-src-preparations);
  object-position: var(--img-description-preparations-position);
}
.step-description-img-Marketing {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-marketing-height);
  content: var(--img-src-marketing);
}
.step-description-img-ViewingBidding {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-viewing-bidding-height);
  content: var(--img-src-viewing-bidding);
  object-position: var(--img-description-viewing-bidding-position);
}
.step-description-img-DownPayment {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-down-payment-height);
  content: var(--img-src-down-payment);
}
.step-description-img-Contract {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-contract-height);
  content: var(--img-src-contract);
}
.step-description-img-Entry {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-entry-height);
  content: var(--img-src-entry);
}
.step-description-img-AfterEntry {
  object-fit: cover; /* Maintain aspect ratio and cover the entire height */
  height: 100%; /* Make the image fill the whole height of the container */
  width: 100%; /* Maintain aspect ratio var tidigare auto */
  max-width: 30%;
  max-height: var(--img-src-after-entry-height);
  content: var(--img-src-after-entry);
  object-position: var(--img-description-after-entry-position);
}

/* Media query for mobile devices */
@media (max-width: 572px) {
  .step-description-container {
    display: grid;
  }
  .step-description-container img {
    margin-right: 0;
    align-items: stretch;
    height: auto;
    max-height: 200px;
    width: 100%;
    max-width: 100%;
  }
}
