.loan-summary {
  @include content-container;
  padding: var(--container-padding-horizontal);

  .loan-commitment-text {
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    align-items: center;
    border: 1px solid var(--input-border-color);
    border-radius: var(--rounded-corners);
    background-color: var(--main-bg-color);
    padding: var(--container-padding-vertical);
  }

  .sp-icon {
    margin-right: 10px;
  }

  .loan-commitment-info {
    width: 100%;
  }

  a {
    text-align: center;
    width: 120px;
  }
}
