$ph-border-radius: var(--rounded-corners);
$ph-cols-remove-odd: false;
$ph-color: var(--content-loader-color);

@import '~/node_modules/placeholder-loading/src/scss/placeholder-loading';

.ph-item {
  border: 0;
  padding: 0;
  background-color: var(--main-bg-color);
}

.ph-avatar {
  height: 80px;
  width: 80px;
}

.ph-row {
  div {
    height: 15px;
  }

  .big,
  &.big div {
    height: 30px;
  }
}

[class*='ph-col'],
.ph-picture {
  margin-top: 10px;
  border-radius: var(--rounded-corners);
}

.header-loader {
  margin-top: 2rem;
}

.home-loader {
  .ph-picture {
    height: 80px;
    width: 80px;
  }
}
@media screen and (min-width: 680px) {
  .ph-col-8 {
    flex: 0 0 83.3333333333%;
  }
}
