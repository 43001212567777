.leading-bid {
  @include content-container;
  padding: var(--container-padding-horizontal);
  background-color: var(--leading-bidding-bg-color);
  border: 1px solid var(--leading-bidding-border-color);

  .price-info {
    margin-bottom: 20px;
    color: var(--leading-bidding-foreground-dark-color);

    .status,
    .extra-info {
      color: var(--leading-bidding-foreground-light-color);
    }

    .price-difference {
      color: var(--leading-bidding-foreground-accent-color);
    }
  }

  .leading-text {
    font-family: var(--body-font);
    text-align: center;
    color: var(--leading-bidding-foreground-dark-color);

    .title {
      font-weight: var(--header-font-weight);
      display: block;
      margin-bottom: 5px;
    }

    .description {
      @include content-paragraph;
    }
  }
}
