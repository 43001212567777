.number-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.number-info {
  @include content-container;
  padding: var(--side-spacing);
  width: 100%;

  .title {
    font-size: var(--font-size-s);
    margin-bottom: var(--side-spacing);
    color: var(--secondary-foreground-light-color);
    font-weight: 600;
  }

  .number {
    font-size: var(--font-size-large-number);
    font-weight: 500;
    color: var(--number-text-color);
  }

  .description {
    font-size: var(--font-size-xs);
    margin-top: var(--side-spacing);
    color: var(--secondary-foreground-light-color);
  }
}
