.carousel {
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    justify-content: center;
  }

  .content {
    display: flex;
    width: 100%;
    transition: transform 0.2s ease-in-out;
    margin-bottom: 5px;
  }

  .content > * {
    width: 100%;
    flex-shrink: 0;
  }

  .button {
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    align-self: center;
    display: none;
  }

  .arrow {
    display: none;
  }

  .dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .dot {
    position: relative;
    height: 10px;
    width: 10px;
    background-color: var(--secondary-bg-color);
    border-radius: 50%;
    margin: 0 5px;
    padding: 0;
  }

  .dot.active {
    background-color: var(--carousel-active-dot-color);
  }
}

@media screen and (min-width: 1024px) {
  .carousel {
    &:hover {
      button {
        opacity: 1;
      }
    }

    .button {
      display: block;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &.left {
        left: 1rem;
        margin-left: -40px;
      }

      &.right {
        right: 1rem;
        margin-right: -40px;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;

        path {
          stroke: var(--secondary-foreground-dark-color);
        }
      }
    }

    .arrow {
      display: block;
    }
  }
}

@media (hover: none) {
  .carousel {
    .button {
      display: none;
    }
  }
}

@media (prefers-reduced-motion) {
  .carousel {
    .content {
      transition: none;
    }
  }
}
