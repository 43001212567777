.recommend {
  @include content-container;
  padding: calc(var(--container-padding-vertical) * 1.5) var(--container-padding-horizontal);
  background-color: var(--recommend-background-color);

  .title {
    font-family: var(--header-font);
    font-weight: var(--header-font-weight);
    font-size: 1.3rem;
  }

  .description {
    font-size: var(--font-size-s);
    color: var(--secondary-foreground-light-color);
    margin: var(--container-padding-vertical) 0;
  }

  .errors {
    font-size: var(--font-size-s);
    color: var(--error-color);
    margin-bottom: 5px;
  }

  .errors.hidden {
    display: none;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    .text-input-container {
      margin-bottom: 5px;
    }
  }

  .success {
    font-size: var(--font-size-s);
    color: var(--success-color);
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 10px;

    width: 150px;
    height: 35px;

    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    color: var(--button-text-color);
    font-size: var(--font-size-s);
    border-radius: var(--rounded-corners);
  }
}
