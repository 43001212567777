@media screen and (min-width: 1024px) {
  .offers-page {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;

      li {
        flex: 0 0 49%;

        .tip {
          height: 100%;
        }
      }
    }
  }
}
