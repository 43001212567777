.tip {
  margin: 2px; // 2022-02-18 KJ Added 1px to allow side shadows to show, this should be remedied elsewhere later

  border-radius: var(--rounded-corners);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background-color: var(--tip-bg-color);

  img {
    width: 100%;
    height: 150px;
    border-radius: var(--rounded-corners) var(--rounded-corners) 0 0;
    object-fit: cover;
  }

  .tip-info {
    padding: var(--container-padding-vertical) var(--container-padding-horizontal);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title {
      color: var(--secondary-foreground-dark-color);
      font-weight: var(--header-font-weight);
      font-family: var(--header-font);
    }

    .description {
      margin: 0.5rem 0 0.65rem;
      @include content-paragraph;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 140px;
      height: 35px;

      background-color: var(--button-background-color);
      color: var(--button-text-color);
      font-size: var(--font-size-s);
      text-decoration: none;
      border-radius: var(--rounded-corners);
      border: 1px solid var(--button-border-color);
      box-shadow: var(--button-box-shadow);
      text-transform: var(--button-text-case);
    }
  }

  &.text-on-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    box-shadow: none;

    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
      border-radius: 0;
      filter: brightness(50%);
    }

    .tip-info {
      position: absolute;
      padding: 0 25px;

      .text {
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .title {
        font-size: var(--font-size-l);
        color: var(--tip-text-on-image-color);
        font-family: var(--header-font);
        font-weight: var(--header-font-weight);
      }

      .description {
        font-size: var(--font-size-s);
        color: var(--tip-text-on-image-color);
        -webkit-line-clamp: 3;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .tip {
    img {
      height: 175px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .tip {
    img {
      height: 200px;
    }
  }
}
