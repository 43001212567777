.expandable {
  .expand-button {
    padding: 0;
    background-color: transparent;
    color: var(--secondary-foreground-dark-color);
    text-align: left;

    svg {
      margin-left: 10px;
      height: var(--font-size-xs);
      width: var(--font-size-xs);

      path {
        stroke: var(--secondary-foreground-dark-color);
      }
    }

    &.expanded {
      margin-bottom: 10px;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
