@font-face {
  font-family: 'Chronicle';
  src: url('../../../public/fonts/fastighetsmaklarna/ChronicleDisp-Light.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-variant: tabular-nums;
}
@font-face {
  font-family: 'DIN';
  src: url('../../../public/fonts/fastighetsmaklarna/DIN-Light.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-variant: tabular-nums;
}

.fastighetsmaklarna {
  --logotype-url: url('https://statistik.boostad.net/img/FASTIGHET/LOGOTYPE/fastighetsm_logo.svg');
  --extra-logotype-url: url('https://statistik.boostad.net/img/FASTIGHET/LOGOTYPE/modified_big_logo_white.png');
  --primary-color: #fff5cc;
  --secondary-color: #000000;
  --button-background-color: transparent;
  --button-border-color: var(--secondary-color);
  --button-text-color: var(--secondary-color);
  --button-active-bg-color: var(--secondary-color);
  --button-text-case: uppercase;
  --secondary-button-background-color: transparent;
  --secondary-button-text-color: var(--secondary-color);
  --secondary-button-border-color: var(--secondary-color);
  /* för den vita kanten på knapparna
  --button-box-shadow:  0px 0px 1px 5px #951111;*/
  --notification-circle-color: var(--accent-bg-color);
  --accent-text-color: var(--accent-color);
  --header-font: 'Chronicle';
  --body-font: 'DIN';

  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);

  --main-bg-color: #ffffff;
  --main-text-color: #1e1e1e;
  --home-page-bg-color: #ffffff;
  --estate-page-bg-color: #ffffff;
  --estate-page-header-bg-color: transparent;
  --sub-header-color: var(--accent-color);
  --description-bg-color: var(--primary-color);
  //--sub-header-img: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/fastighetsmaklarna_backgr.jpg');
  --sub-header-text-color: #ffffff;
  --accent-color: #206464;
  --accent-bg-color: #dcc9c9;
  --estate-info-starting-price-font-size: var(--font-size-m);
  --secondary-bg-color: #ffffff; // #f7f7f7;
  --secondary-text-color: #000000;
  --secondary-foreground-light-color: var(--main-text-color);
  --secondary-foreground-dark-color: var(--main-text-color);
  --lowest-possible-bid-text-color: var(--secondary-foreground-light-color);
  --secondary-button-text-color: var(--main-text-color);
  --secondary-button-border-color: var(--main-text-color);
  --sp-button-bg-color: var(--secondary-color);
  --button-active-bg-color: #3a3a3a;
  --process-nav-bar-bg-color: #ffffff;
  --process-nav-bar-bar-color: #ffffff;
  --process-nav-bar-fill-color: #ffffff;
  --process-nav-bar-border-color: #ffffff;
  --process-nav-bar-text-color: #000000;
  --process-nav-bar-active-bg-color: #ffffff;
  --process-nav-bar-active-border-top: transparent;
  --process-nav-bar-active-border-right: transparent;
  --process-nav-bar-active-border-bottom: 3px solid var(--accent-bg-color);
  --process-nav-bar-active-border-left: transparent;
  --process-nav-bar-active-text-color: #000000;
  --process-nav-bar-case: uppercase;
  --process-nav-bar-margin: -5px 0px 0px 0px;
  --top-nav-bar-padding: 7px 30px 7px 30px;

  --top-bar-background: #ffffff; //header
  --current-step-marker-color: var(--accent-bg-color);
  --current-step-marker-border-color: var(--accent-bg-color);

  --checklist-started-items-bg-color: transparent;
  --checklist-started-items-foreground-color: #1e1e1e;
  --checklist-finished-items-bg-color: var(--main-bg-color);
  --checklist-finished-items-foreground-color: #1e1e1e;
  --checklist-items-bg-color: transparent;
  --checklist-border-color: var(--secondary-bg-color);
  --checklist-finished-border-color: var(--secondary-bg-color);
  --checklist-started-border-color: var(--secondary-bg-color);

  --text-link-color: var(--secondary-color);
  --tip-bg-color: #ffffff;
  --profile-menu-button-bg-color: transparent;
  --profile-menu-button-border-color: var(--main-text-color);
  --profile-menu-button-log-out-text-color: var(--main-text-color);
  --profile-menu-button-cancel-text-color: var(--main-text-color);
  --profile-menu-name-text-color: var(--main-text-color);

  --onboarding-button-bg-color: transparent;
  --onboarding-button-border-color: var(--secondary-color);
  --onboarding-button-text-color: var(--secondary-color);

  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-outline-color: #47474a;
  --bottom-nav-bar-icon-active-bg-color: var(--secondary-color);
  --bottom-nav-bar-icon-active-contrast-color: var(--main-bg-color);

  --container-separator-color: var(--secondary-foreground-light-color);

  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--secondary-text-color);
  --checkbox-checked-bg-color: var(--accent-bg-color);
  --checkbox-checked-foreground-color: var(--secondary-text-color);
  --checkbox-focus-outline-color: #aaa;

  --input-border-focus-color: var(--accent-text-color);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-text-color);

  --alert-warning-bg-color: transparent;
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #ff6f6f;
  --alert-error-text-color: #1e1e1e;

  --chart-tooltip-bg-color: var(--secondary-bg-color);
  --chart-tooltip-foreground-color: var(--secondary-text-color);
  --chart-tooltip-border-color: #47474a;
  --chart-legend-label-color: var(--secondary-foreground-light-color);
  --chart-label-color: #666;
  --chart-grid-line-color: rgb(97, 97, 97);

  --bid-history-line-color: #47474a;
  --bid-history-starting-price-color: #47474a;
  --chart-data-user-color: var(--accent-bg-color);
  --chart-data-color-1: #ccc;
  --chart-data-color-2: #999;
  --chart-data-color-3: #666;
  --chart-data-color-4: #333;
  --chart-data-color-5: #aaa;
  --chart-data-color-6: #555;
  --chart-data-color-7: #888;

  --impressions-total-data-color: transparent;
  --impressions-total-border-color: var(--accent-color);
  --impressions-broker-channel-data-color: transparent;
  --impressions-broker-channel-border-color: var(--accent-bg-color); // #9c9c9c;
  --impressions-per-channel-data-color: var(--accent-color);

  --broker-container-border-color: #000000;
  --broker-container-box-shadow: 0px 0px 1px 7px #dcc9c9;
  --broker-container-bg-color: #dcc9c9;
  --broker-image-bg-color: transparent;
  --broker-image-radius: 0%;
  --broker-image-height: 150px;
  --broker-image-width: 120px;
  --broker-title-color: #1e1e1e;
  --broker-image-margin: -15px 15px -15px -20px;
  --broker-container-top-margin: 7px;

  --forms-background: var(--primary-color);
  --input-border-color: #b6b6b6;
  --input-label-color: var(--secondary-text-color);
  --input-required-color: var(--input-label-color);
  --input-bg-color: #fff8d7;
  --input-text-color: var(--main-text-color);
  --bids-list-every-other-color: #f2f2f2;

  --notification-icon-read-bg-color: var(--secondary-color);
  --notification-icon-read-foreground-color: #ffffff;
  --new-notification-circle-color: var(--accent-bg-color);
  --notification-icon-not-read-bg-color: var(--accent-bg-color);
  --notification-icon-not-read-foreground-color: #ffffff;
  --notification-main-icon-color: #000000;

  --leading-bidding-bg-color: var(--secondary-bg-color);
  --leading-bidding-border-color: var(--main-bg-color);
  --leading-bidding-foreground-dark-color: var(--main-text-color);
  --leading-bidding-foreground-light-color: var(--main-text-color);
  --leading-bidding-foreground-accent-color: var(--main-text-color);

  --logotype-height: 40px;
  --logotype-height-mobile: 40px;
  --logotype-padding-bottom: 0px;
  --top-margin: 0px;
  --rounded-corners: 1px;
  --round-corners: 5px;
  --content-loader-color: #f7f7f7;
  //--broker-name-case: lowercase;
  --step-description-case: none;
  --title-font-size: 2.1rem;
  --text-align: left;
  //header och footer täcker hela sidan
  --max-content-width: 1200px;
  --max-page-width: none;
  --side-spacing: 10px;
  --main-page-side-spacing: 0px;
  // --special-margin: 0px -10px 0px -10px;
  //--special-padding: 0px 10px 0px 10px;
  --step-description-font-weight: normal;
  --process-step-margin: 20px auto 0px auto;
  --home-page-estates-top-padding: 30px;
  --process-page-padding: 10px;
  //dessa   tar bort den inre scrollisten på sidorna
  --process-step-overflow: 0;
  --process-step-scroll-height: none;
  --bidding-page-scroll-height: none;
  --main-header-padding: 0px;
  --large-container-padding: 25px 30px 20px;
  --recommend-background-color: var(--primary-color);
  --img-src-estate-data: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/nyckel_dorr.jpg');
  --img-src-preparations: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/18_tavlor_portratt.jpg');
  --img-src-marketing: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/24_head.jpg');
  --img-src-viewing-bidding: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/nyckel_dorr.jpg');
  --img-src-down-payment: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/4_soffa_hylla.jpg');
  --img-src-contract: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/flyttlador.jpg');
  --img-src-entry: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/stege.jpg');
  --img-src-after-entry: url('https://statistik.boostad.net/img/FASTIGHET/IMAGE/12_fasad.jpg');
  --img-src-preparations-height: 280px;
  --img-src-contract-height: 240px;
  --img-src-after-entry-height: 230px;
  --img-src-entry-height: 230px;
  --img-src-down-payment-height: 250px;

  //footer
  .footer {
    background-color: #fff5cc;
  }
  .footer_img {
    padding-top: 50px;
  }
}
