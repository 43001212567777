.choose-loan-commitment-bank {
  .current-choice {
    display: block;
    margin-bottom: 20px;
    font-style: italic;
  }

  .text-input-container {
    margin-top: 10px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 20px;

    a {
      margin-left: 5px;
      color: var(--text-link-color);
      text-decoration: none;
    }

    button:disabled {
      background-color: var(--button-disabled-color);
      border-color: var(--button-disabled-color);
    }
    button {
      border: 1px solid var(--button-border-color);
      background-color: var(--button-background-color);
    }
  }
}

.bank-radio-input {
  display: flex;
  justify-content: space-between;
  padding: var(--container-padding-vertical) var(--container-padding-horizontal);
  border: 1px solid var(--container-separator-color);
  border-radius: var(--rounded-corners);

  > span {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  img {
    height: calc(var(--font-size) * 1.5);
  }

  // Hide native checkbox
  input[type='radio'] {
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }

  .radio-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--font-size);
    width: var(--font-size);
    background-color: var(--checkbox-bg-color);
    border: 1px solid var(--checkbox-border-color);
    border-radius: 50%;

    &.focused {
      outline: 3px solid var(--checkbox-focus-outline-color);
    }

    .checked-mark {
      width: var(--font-size-xs);
      height: var(--font-size-xs);
      background-color: var(--checkbox-checked-bg-color);
      border-radius: 50%;
    }
  }
}

.sp-contact {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 70%;
    padding: 10px 20px;
    background-color: var(--sp-bolan-logotype-bg-color);
  }

  .alert {
    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;

    button {
      width: 100%;
      background-color: var(--sp-button-bg-color);
      border-color: var(--sp-button-bg-color);
      color: var(--sp-button-text-color);

      &:disabled {
        background-color: var(--button-disabled-color);
      }
    }
  }
}
