.personal-number-login {
  width: 100%;

  .alert {
    margin-top: 0;
  }

  .error-text {
    display: block;
    font-size: var(--font-size-s);
    color: var(--error-color);
    margin-bottom: 5px;
  }

  .personal-number-started-instruction {
    display: inline-block;
    margin-top: 10px;
  }
}
