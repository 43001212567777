.example-broker {
  --logotype-url: url('../../../public/images/example-broker/logotype.svg');
}

@media (prefers-color-scheme: dark) {
  .example-broker {
    --logotype-url: url('../../../public/images/example-broker/logotype-darkmode.svg');
    --main-bg-color: #1e1e1e;
    --main-text-color: #fff;

    --secondary-bg-color: #3a3a3a;
    --secondary-text-color: #fff;
    --secondary-foreground-light-color: rgb(197, 197, 197);
    --secondary-foreground-dark-color: var(--main-text-color);

    --process-nav-bar-background-color: #1e1e1e;
    --process-nav-bar-border-color: #1e1e1e;
    --process-nav-bar-active-text-color: #fff;

    --text-link-color: #70d4ff;

    --tip-bg-color: var(--secondary-bg-color);

    --profile-menu-button-bg-color: transparent;
    --profile-menu-button-border-color: #fff;
    --profile-menu-button-log-out-text-color: #fff;
    --profile-menu-button-cancel-text-color: #fff;

    --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
    --bottom-nav-bar-icon-outline-color: #fff;
    --bottom-nav-bar-icon-active-contrast-color: var(--main-bg-color);

    --container-separator-color: var(--secondary-foreground-light-color);

    --input-bg-color: var(--main-bg-color);

    --alert-info-bg-color: var(--secondary-bg-color);
    --alert-info-text-color: var(--secondary-text-color);

    --chart-tooltip-bg-color: var(--secondary-bg-color);
    --chart-tooltip-foreground-color: var(--secondary-text-color);
    --chart-tooltip-border-color: rgb(236, 236, 236);
    --chart-legend-label-color: var(--secondary-foreground-light-color);
    --chart-label-color: rgb(196, 196, 196);
    --chart-grid-line-color: rgb(97, 97, 97);
    --point-border-color: rgb(236, 236, 236);
    --lowest-possible-bid-text-color: var(--secondary-foreground-light-color);
    --bid-history-line-color: #fff;
    --bid-history-starting-price-color: #fff;
    --bids-list-every-other-color: #333;

    --broker-image-bg-color: var(--secondary-foreground-dark-color);
    --broker-container-bg-color: var(--secondary-bg-color);
  }
}
