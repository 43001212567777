.error {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 100px;
    margin-bottom: 30px;
  }
}
