.checklist {
  .list {
    margin-top: 20px;
    padding-bottom: 20px;

    .list-title {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 20px;
    }
  }

  p + .list {
    margin-top: 40px;
  }

  a:link {
    color: var(--login-video-link);
  }

  a:visited {
    color: var(--login-video-link);
  }

  a:hover {
    color: var(--login-video-link);
  }
  a:active {
    color: var(--login-video-link);
  }
}
