.checklist-item {
  display: flex;

  .title {
    font-weight: 400;
    display: block;
  }

  .description {
    font-size: var(--font-size-s);
    color: var(--secondary-text-color);
    white-space: pre-wrap;
  }

  // Hide native checkbox
  input[type='checkbox'] {
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }

  .checkbox {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    background-color: var(--checkbox-bg-color);
    border: 1px solid var(--checkbox-border-color);
    margin: 0 10px;
    border-radius: 50%;

    svg path {
      stroke: var(--checkbox-bg-color);
    }

    &.focused {
      outline: 4px solid var(--checkbox-focus-outline-color);
    }

    &.checked {
      border-color: var(--checkbox-checked-border-color);
      background-color: var(--checkbox-checked-bg-color);

      svg path {
        stroke: var(--checkbox-checked-foreground-color);
      }
    }
  }
}
