.bidding-status {
  @include content-container;
  padding: var(--container-padding-horizontal);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  .title {
    font-weight: var(--header-font-weight);
  }

  .description {
    margin-top: 5px;
    @include content-paragraph;
  }
}
