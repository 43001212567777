.top-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--top-bar-background);
  padding: var(--top-nav-bar-padding);

  .icons {
    svg {
      height: 25px;
      padding: 7.5px;

      path {
        stroke: var(--notification-main-icon-color);
      }
    }

    a:first-child {
      margin-right: 10px;
    }
  }

  .notification-circle {
    display: none;
  }

  .new-notifications {
    .notification-circle {
      display: inline;
      fill: var(--new-notification-circle-color);
    }
  }

  button {
    padding: 0;
    background-color: transparent;
  }
}

.menu-position {
  flex-wrap: nowrap;
  display: flex;
}
.menu {
  a:link {
    color: var(--main-text-color);
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: var(--main-text-color);
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: var(--accent-bg-color);
    background-color: transparent;
    text-decoration: none;
  }

  a:active {
    color: var(--accent-bg-color);
    background-color: transparent;
    text-decoration: none;
  }

  .active-menu,
  .inactive-menu {
    display: inline-flex; /* Set both menus to be inline-block */
    height: 80px; /* Set height to the tallest menu */
    padding: 0 6px;
    align-items: center; /* Align items vertically center */
    font-size: var(--font-size-s);
    margin-top: -10px;
    margin-bottom: -7px;
  }

  .active-menu {
    background-color: var(--accent-bg-color);
    color: #ffffff;
  }

  .inactive-menu {
    background-color: transparent; /* Set background color to transparent */
    color: #000000; /* Set color for inactive menu */
  }
}

.top-nav-bar-logo-center {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // skärmar större än 572px får centrerad logga
  @media screen and (min-width: 572px) {
    justify-content: unset;
    .logoLink {
      margin-left: auto;
    }

    div {
      margin-left: auto;
    }
  }

  .icons {
    svg {
      height: 25px;
      padding: 7.5px;

      path {
        stroke: var(--main-text-color);
      }
    }

    a:first-child {
      margin-right: 10px;
    }
  }

  .notification-circle {
    display: none;
  }

  .new-notifications {
    .notification-circle {
      display: inline;
      fill: var(--new-notification-circle-color);
    }
  }

  button {
    padding: 0;
    background-color: transparent;
  }
}
