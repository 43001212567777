.custom-form {
  /*-------DRIFTSKOSTNADER - Vitec ------*/
  .header {
    display: block; /* eller inline-block */
    margin-bottom: 20px;
  }
  .sub-header {
    display: block; /* eller inline-block */
    font-size: 0.9rem;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  /* Lägg till grid layout på de rader som har klasserna double-column eller single-column */
  .horizontal-layout:has(.double-column, .single-column) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  /* Säkerställ att rätt element tar upp rätt utrymme */
  .double-column {
    grid-column: span 2;
    width: calc(100% + 3px); /* Öka bredden med 3px */
  }

  /* andra elementet i den horisontella layouten om det finns exakt två .horizontal-layout-item på den raden  */
  .horizontal-layout:has(> .horizontal-layout-item:nth-child(2):nth-last-child(1)) .horizontal-layout-item:nth-child(2) {
    margin-left: 3px;
  }

  .single-column {
    grid-column: span 1;
  }

  /* Lägg till flex layout på de rader som har klassen inline-checkbox */
  .horizontal-layout:has(.inline-checkbox) {
    display: flex;
    align-items: center;
  }

  /* Ursprungliga stylingen blev tokig i safari */
  /* Inline checkbox styling */
  .inline-checkbox {
    display: flex;
  }

  /* Justera checkboxarna så att de hamnar i linje med texten */
  .inline-checkbox input[type='checkbox'] {
    margin-top: 5px;
  }

  /* Justera texten så att den hamnar i linje med checkboxarna*/
  .inline-checkbox label {
    vertical-align: middle;
  }

  .empty-cell {
    display: none;
  }

  .letter-text {
    textarea {
      min-height: 250px;
      font-family: var(--handwriting-font);
      font-size: var(--font-size-m);
    }
    label {
      visibility: hidden;
      height: 1px;
    }
  }

  /*Tar bort grid så att även de med endast två kolumner ska hamna på två rader*/
  @media screen and (max-width: 572px) {
    .horizontal-layout:has(.double-column, .single-column) {
      display: block;
    }
    /* samma med checkbox */
    .horizontal-layout:has(.inline-checkbox) {
      display: block;
    }

    .double-column {
      grid-column: span 2;
      width: 100%;
    }
    .horizontal-layout:has(> .horizontal-layout-item:nth-child(2):nth-last-child(1)) .horizontal-layout-item:nth-child(2) {
      margin-left: 0;
    }
  }
}
