.onboarding-page {
  .onboarding-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .onboarding-content > *,
  aside > * {
    margin-bottom: var(--content-spacing);
  }

  .onboarding-content-container {
    height: calc(100vh - var(--header-size));
    overflow: auto;
  }

  .grouped {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 480px;
  }

  span {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .buttons {
    button {
      margin-left: 10;
      margin-right: 10;
      width: 90%;
      border-radius: var(--rounded-corners);
      margin: 5px 0;
      background-color: var(--onboarding-button-bg-color);
      border: 1px solid var(--onboarding-button-border-color);
      color: var(--onboarding-button-text-color);
    }
  }
}

@media screen and (min-width: 572px) {
  .onboarding-page {
    aside {
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;

        li {
          flex: 1 0 48%;
          margin-bottom: 0;

          .tip {
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .onboarding-page {
    .onboarding-content-container {
      padding: 0;
      width: 100%;
      display: grid;
      grid-template-columns: 63% 35%;
      gap: 1rem;

      .onboarding-content {
        max-width: 100%; // Without this the charts break the design
      }

      aside {
        ul {
          display: block;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
