.demomaklaren-homepage-layout {
  --logotype-url: url('https://infoping.net/img/logo/demowhite.png');
  --primary-color: #215e7b;
  --secondary-color: #215e7b; // #dedbd5;
  --button-background-color: var(--primary-color);
  --button-border-color: var(--primary-color);
  --button-text-color: #ffffff;
  --button-active-bg-color: var(--primary-color);
  --secondary-button-background-color: transparent;
  --secondary-button-text-color: var(--primary-color);
  --secondary-button-border-color: var(--primary-color);
  /* för den vita kanten på knapparna
  --button-box-shadow:  0px 0px 1px 5px #951111;*/
  --notification-circle-color: var(--accent-bg-color);
  --accent-text-color: #47474a;
  --header-font: 'Raleway';
  --body-font: 'Raleway';

  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);

  --main-bg-color: #ffffff;
  --main-text-color: #47474a;
  --home-page-bg-color: #f2f2f2;
  --estate-page-bg-color: #f2f2f2;
  --estate-page-header-bg-color: #ffffff;
  --accent-bg-color: #215e7b;
  --description-bg-color: var(--secondary-bg-color);
  --estate-info-starting-price-font-size: var(--font-size-m);
  /*951111 röd   c0c4c4  grå*/
  --secondary-bg-color: #ffffff; // #e0dcd4; //#f8f8f8;
  --secondary-text-color: #000000;
  --secondary-foreground-light-color: var(--main-text-color);
  --secondary-foreground-dark-color: var(--main-text-color);
  --lowest-possible-bid-text-color: var(--secondary-foreground-light-color);
  --secondary-button-text-color: #47474a;
  --secondary-button-border-color: #47474a;

  --button-active-bg-color: #3a3a3a;
  --process-nav-bar-bg-color: var(--main-bg-color);
  --process-nav-bar-bar-color: var(--main-bg-color);
  --process-nav-bar-fill-color: var(--main-bg-color);
  --process-nav-bar-border-color: var(--main-bg-color);
  --process-nav-bar-text-color: #1e1e1e;
  --process-nav-bar-active-bg-color: var(--main-bg-color);
  --process-nav-bar-active-border-top: transparent;
  --process-nav-bar-active-border-right: transparent;
  --process-nav-bar-active-border-bottom: 3px solid var(--accent-bg-color);
  --process-nav-bar-active-border-left: transparent;
  --process-nav-bar-active-text-color: #1e1e1e;
  --process-nav-bar-case: uppercase;
  --process-nav-bar-margin: -5px 0px 0px 0px;
  --top-nav-bar-padding: 7px 30px 7px 30px;
  --top-bar-background: var(--secondary-color);
  --current-step-marker-color: var(--accent-bg-color);
  --current-step-marker-border-color: var(--accent-bg-color);

  --checklist-started-items-bg-color: transparent;
  --checklist-started-items-foreground-color: #1e1e1e;
  --checklist-finished-items-bg-color: var(--main-bg-color);
  --checklist-finished-items-foreground-color: #1e1e1e;
  --checklist-items-bg-color: transparent;
  --checklist-border-color: var(--secondary-bg-color);
  --checklist-finished-border-color: var(--secondary-bg-color);
  --checklist-started-border-color: var(--secondary-bg-color);

  --text-link-color: var(--primary-color);

  --tip-bg-color: var(--secondary-bg-color); //var(--secondary-color) transparent;

  --profile-menu-button-bg-color: transparent;
  --profile-menu-button-border-color: var(--main-text-color);
  --profile-menu-button-log-out-text-color: var(--main-text-color);
  --profile-menu-button-cancel-text-color: var(--main-text-color);
  --profile-menu-name-text-color: var(--main-text-color);

  --onboarding-button-bg-color: transparent;
  --onboarding-button-border-color: var(--primary-color);
  --onboarding-button-text-color: var(--primary-color);

  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-outline-color: #47474a;
  --bottom-nav-bar-icon-active-contrast-color: var(--main-bg-color);

  --container-separator-color: var(--secondary-foreground-light-color);

  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--secondary-text-color);
  --checkbox-checked-bg-color: var(--accent-bg-color);
  --checkbox-checked-foreground-color: #ffffff;
  --checkbox-focus-outline-color: #aaa;

  --input-border-focus-color: var(--accent-text-color);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-text-color);

  --alert-warning-bg-color: transparent;
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #ff6f6f;
  --alert-error-text-color: #1e1e1e;

  --chart-tooltip-bg-color: var(--secondary-bg-color);
  --chart-tooltip-foreground-color: var(--secondary-text-color);
  --chart-tooltip-border-color: #47474a;
  --chart-legend-label-color: var(--secondary-foreground-light-color);
  --chart-label-color: #666;
  --chart-grid-line-color: rgb(97, 97, 97);

  --bid-history-line-color: #47474a;
  --bid-history-starting-price-color: #47474a;
  --chart-data-user-color: var(--accent-bg-color);
  --chart-data-color-1: #ccc;
  --chart-data-color-2: #999;
  --chart-data-color-3: #666;
  --chart-data-color-4: #333;
  --chart-data-color-5: #aaa;
  --chart-data-color-6: #555;
  --chart-data-color-7: #888;

  --impressions-total-data-color: transparent;
  --impressions-total-border-color: var(--secondary-color);
  --impressions-broker-channel-data-color: transparent;
  --impressions-broker-channel-border-color: #9c9c9c;
  --impressions-per-channel-data-color: var(--secondary-color);

  --broker-container-bg-color: #000000;
  --broker-image-bg-color: tansparent;
  --broker-image-radius: 0%;
  --broker-image-height: 150px;
  --broker-image-width: 120px;
  --broker-title-color: #dedbd5;
  --broker-image-margin: -15px 15px -15px -20px;

  --input-border-color: #b6b6b6;
  --input-label-color: var(--secondary-text-color);
  --input-required-color: var(--input-label-color);
  --input-bg-color: var(--home-page-bg-color); //var(--main-bg-color);
  --input-text-color: var(--main-text-color);
  --bids-list-every-other-color: #f2f2f2;

  --notification-icon-read-bg-color: var(--secondary-color);
  --notification-icon-read-foreground-color: var(--main-text-color);
  --new-notification-circle-color: var(--accent-bg-color);
  --notification-icon-not-read-bg-color: var(--accent-bg-color);
  --notification-icon-not-read-foreground-color: #1e1e1e;

  --leading-bidding-bg-color: var(--secondary-bg-color);
  --leading-bidding-border-color: var(--main-bg-color);
  --leading-bidding-foreground-dark-color: var(--main-text-color);
  --leading-bidding-foreground-light-color: var(--main-text-color);
  --leading-bidding-foreground-accent-color: var(--main-text-color);

  --notification-icon-not-read-foreground-color: #1e1e1e;
  --logotype-height: 40px;
  --logotype-height-mobile: 40px;
  --logotype-padding-bottom: 0px;
  --top-margin: 0px;
  --rounded-corners: 1px;
  --round-corners: 5px;
  --content-loader-color: var(--home-page-bg-color);
  --broker-name-case: uppercase;
  --step-description-case: none;
  --title-font-size: 2.1rem;
  --text-align: left;
  //header och footer täcker hela sidan
  --max-content-width: 1200px;
  --max-page-width: none;
  --side-spacing: 10px;
  --main-page-side-spacing: 0px;
  // --special-margin: 0px -10px 0px -10px;
  //--special-padding: 0px 10px 0px 10px;
  --step-description-font-weight: bold;
  --process-step-margin: 20px auto 0px auto;
  --home-page-estates-top-padding: 30px;
  --process-page-padding: 10px;
  //dessa   tar bort den inre scrollisten på sidorna
  --process-step-overflow: 0;
  --process-step-scroll-height: none;
  --bidding-page-scroll-height: none;
  --main-header-padding: 0px;
  --large-container-padding: 25px 30px 20px;
  --forms-button-add-text-color: #000000;
  --forms-background: #f9f9f9;
  --recommend-background-color: var(--secondary-bg-color);
}
