.bottom-nav-bar {
  background: var(--main-bg-color);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;

    > a {
      max-height: 30px;
    }
  }

  // Icons standard
  .process-icon {
    fill: var(--bottom-nav-bar-icon-bg-color);

    .process-lines {
      stroke: var(--bottom-nav-bar-icon-outline-color);
    }
  }

  .checklists-icon {
    background-color: var(--bottom-nav-bar-icon-bg-color);
    border-radius: 2px;

    .checklists-lines {
      stroke: var(--bottom-nav-bar-icon-outline-color);
    }

    .checklists-circles {
      fill: var(--bottom-nav-bar-icon-outline-color);
    }
  }

  .documents-icon {
    .documents-tab-lines {
      fill: var(--bottom-nav-bar-icon-outline-color);
      stroke: none;
    }

    .documents-tab {
      fill: var(--bottom-nav-bar-icon-outline-color);
    }

    .documents-space {
      fill: var(--bottom-nav-bar-icon-bg-color);
    }
  }

  .offers-icon {
    fill: var(--bottom-nav-bar-icon-bg-color);

    .offers-lines {
      stroke: var(--bottom-nav-bar-icon-outline-color);
    }

    .offers-circle {
      stroke: var(--bottom-nav-bar-icon-outline-color);
    }
  }

  // Icons active
  .active {
    .process-icon {
      fill: var(--bottom-nav-bar-icon-active-bg-color);

      .process-lines {
        stroke: var(--bottom-nav-bar-icon-active-bg-color);
      }
    }

    .checklists-icon {
      background-color: var(--bottom-nav-bar-icon-active-bg-color);

      .checklists-lines {
        stroke: var(--bottom-nav-bar-icon-active-contrast-color);
      }

      .checklists-circles {
        fill: var(--bottom-nav-bar-icon-active-contrast-color);
      }
    }

    .documents-icon {
      .documents-tab-lines {
        fill: var(--bottom-nav-bar-icon-active-contrast-color);
        stroke: var(--bottom-nav-bar-icon-active-bg-color);
      }

      .documents-tab {
        fill: var(--bottom-nav-bar-icon-active-contrast-color);
      }

      .documents-space {
        fill: var(--bottom-nav-bar-icon-active-bg-color);
      }
    }

    .offers-icon {
      fill: var(--bottom-nav-bar-icon-active-bg-color);

      .offers-lines {
        stroke: var(--bottom-nav-bar-icon-active-bg-color);
      }

      .offers-circle {
        fill: var(--bottom-nav-bar-icon-active-contrast-color);
        stroke: var(--bottom-nav-bar-icon-active-bg-color);
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .bottom-nav-bar {
    div {
      max-width: 1280px;
    }
  }
}
