.bid-list {
  @include content-container;
  background-color: var(--primary-bg-color);
  border: 1px solid var(--container-separator-color);
  display: flex;
  justify-content: space-between;

  .bid-amount {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .bidder {
    font-size: var(--font-size-xs);
    color: var(--secondary-text-color);
  }

  .date {
    font-weight: 500;
  }
}
