.alert {
  text-align: center;
  border-radius: var(--rounded-corners);
  padding: var(--container-padding-vertical) var(--container-padding-horizontal);

  &.info {
    background-color: var(--alert-info-bg-color);
    color: var(--alert-info-text-color);
  }

  &.warning {
    background-color: var(--alert-warning-bg-color);
    color: var(--alert-warning-text-color);
  }

  &.error {
    background-color: var(--alert-error-bg-color);
    color: var(--alert-error-text-color);
  }
}
