@font-face {
  font-family: 'Neutra';
  src: url('../../../public/fonts/skandiamaklarna/NeutraText-Book.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibri';
  src: url('../../../public/fonts/skandiamaklarna/calibri.ttf');
  font-weight: normal;
  font-style: normal;
}
.skandiamaklarna {
  --logotype-url: url('../../../public/images/skandiamaklarna/SkandiaMaklarna_realestate.png');
  --primary-color: #fff;
  --secondary-color: #cfd5d8; // #DDD;
  --accent-bg-color: #feefdb;
  --button-background-color: transparent;
  --button-border-color: var(--primary-color);
  --notification-circle-color: var(--primary-color);
  --accent-text-color: #feefdb;
  --header-font: 'Neutra';
  --body-font: 'Calibri';

  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);

  --main-bg-color: #000000;
  --main-text-color: #fff;
  --description-bg-color: var(--secondary-bg-color);
  --secondary-bg-color: #3a3a3a;
  --secondary-text-color: #fff;
  --number-text-color: var(--main-text-color);
  --total-views-color: var(--main-text-color);
  --secondary-foreground-light-color: #cfd5d8; // #DDD;
  --secondary-foreground-dark-color: var(--main-text-color);
  --sp-button-bg-color: var(--secondary-color);
  --sp-button-text-color: #000000;

  --secondary-button-text-color: #fff;
  --secondary-button-border-color: #fff;
  --button-active-bg-color: #3a3a3a;
  --process-nav-bar-bg-color: var(--secondary-color);
  --process-nav-bar-border-color: var(--secondary-color);
  --process-nav-bar-text-color: #1e1e1e;
  --process-nav-bar-active-bg-color: transparent;
  --process-nav-bar-active-border-color: var(--primary-color);
  --process-nav-bar-active-text-color: var(--main-text-color);
  --current-step-marker-color: var(--accent-bg-color);
  --current-step-marker-border-color: #999;

  --checklist-started-items-bg-color: var(--accent-bg-color);
  --checklist-started-items-foreground-color: #1e1e1e;
  --checklist-finished-items-bg-color: #d7ffd0;
  --checklist-finished-items-foreground-color: #1e1e1e;
  --logotype-height: 50px;
  --logotype-height-mobile: 40px;
  --logotype-max-width: 400px;
  --logotype-padding-bottom: 5px;

  --text-link-color: #70d4ff;
  --font-size-s: 1rem;
  --font-size-xs: 1rem;
  --tip-bg-color: var(--secondary-bg-color);

  --profile-menu-button-bg-color: transparent;
  --profile-menu-button-border-color: #fff;
  --profile-menu-button-log-out-text-color: #fff;
  --profile-menu-button-cancel-text-color: #fff;
  --profile-menu-name-text-color: #fff;

  --onboarding-button-bg-color: transparent;
  --onboarding-button-border-color: #fff;
  --onboarding-button-text-color: #fff;

  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-outline-color: #fff;
  --bottom-nav-bar-icon-active-contrast-color: var(--main-bg-color);

  --container-separator-color: var(--secondary-foreground-light-color);

  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--secondary-text-color);
  --checkbox-checked-bg-color: var(--secondary-color);
  --checkbox-checked-foreground-color: #000000;
  --checkbox-focus-outline-color: #aaa;

  --input-border-focus-color: var(--accent-text-color);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-text-color);

  --alert-warning-bg-color: var(--accent-bg-color);
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #ff6f6f;
  --alert-error-text-color: #1e1e1e;

  --chart-tooltip-bg-color: var(--secondary-bg-color);
  --chart-tooltip-foreground-color: var(--secondary-text-color);
  --chart-tooltip-border-color: rgb(236, 236, 236);
  --chart-legend-label-color: var(--secondary-foreground-light-color);
  --chart-label-color: rgb(196, 196, 196);
  --chart-grid-line-color: rgb(97, 97, 97);

  --bid-history-line-color: #fff;
  --bid-history-starting-price-color: #fff;
  --chart-data-color-1: #ccc;
  --chart-data-color-2: #aaa;
  --chart-data-color-3: #999;
  --chart-data-color-4: #666;
  --chart-data-color-5: #555;
  --chart-data-color-6: #474747;
  --chart-data-color-7: #888;

  --impressions-total-data-color: var(--secondary-color);
  --impressions-broker-channel-data-color: #9c9c9c;
  --impressions-per-channel-data-color: var(--secondary-color);
  --impressions-total-border-color: var(--impressions-total-data-color);
  --impressions-broker-channel-border-color: var(--impressions-broker-channel-data-color);
  --lowest-possible-bid-text-color: var(--secondary-foreground-light-color);
  --broker-icon-bg-color: #979797;
  --broker-container-bg-color: var(--secondary-bg-color);
  --broker-icon-bg-color: #cfd5d8; //#DDD;
  --broker-image-radius: 0;
  --broker-image-bg-color: tansparent;
  --broker-image-radius: 0%;
  --broker-image-height: 160px;
  --broker-title-color: #ffffff;

  --input-border-color: #b6b6b6;
  --input-label-color: var(--secondary-text-color);
  --input-required-color: var(--input-label-color);
  --input-bg-color: rgb(70, 70, 70);
  --input-text-color: var(--main-text-color);
  --bids-list-every-other-color: rgb(71, 71, 71);

  --notification-icon-read-bg-color: #6d6d6d;
  --notification-icon-read-foreground-color: var(--main-text-color);
  --new-notification-circle-color: var(--accent-bg-color);
  --notification-icon-not-read-bg-color: var(--accent-bg-color);
  --notification-icon-not-read-foreground-color: #1e1e1e;
  --login-video-link: #ffffff;
  --rounded-corners: 0;
  --round-corners: 0;
  --forms-background: #3a3a3a;
  --forms-button-add-text-color: #ffffff;
  --recommend-background-color: var(--secondary-bg-color);
}
