.viewing {
  @include content-container;

  .date {
    text-transform: capitalize;
    font-weight: 500;
  }

  .bookings,
  .visitors,
  .comment {
    font-size: var(--font-size-xs);
    color: var(--secondary-foreground-light-color);
    padding-top: 5px;
  }
}
