.forms {
  @include content-container;
  padding: calc(var(--container-padding-vertical) * 1.5) var(--container-padding-horizontal);
  .group-layout {
    padding: 0;
    margin: 0;
    border: none;
    margin-top: 10px;
  }
  input[type='checkbox'] {
    height: 21px;
  }
  .money {
    width: inherit;
  }
  .improvement {
    display: flex;
    flex-direction: row;
  }

  .one-row {
    flex: 1; /* Adjust the flex value based on your layout */
  }

  .button-container {
    margin-left: 10px; /* Adjust the margin based on your layout */
  }
  legend {
    font-family: var(--header-font);
    font-weight: var(--header-font-weight);
    font-size: var(--font-size-form-headers);
    margin: 15px 0 15px 0;
  }

  .group-description {
    font-size: var(--font-size-s);
    color: var(--secondary-foreground-light-color);
    margin: 0 5px 15px 5px;
  }
  .group-description-addition {
    font-size: var(--font-size-s);
    color: var(--secondary-foreground-light-color);
    margin: 0 5px 15px 5px;
    font-style: italic;
  }
  --input-spacing-left: 10px;

  .save-form-description {
    margin-top: 15px;
    font-size: var(--font-size-s);
    color: var(--secondary-foreground-light-color);
    margin: var(--container-padding-vertical) 0;
  }
  .control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    font-family: var(--body-font);
    font-size: 0.85rem;
    min-height: 50px;
    width: 100%;
    border-radius: var(--rounded-corners);
    border: 1px solid var(--input-border-color);
    margin-bottom: 10px;

    &.validation_error {
      border: 1px solid var(--error-color);
    }

    .error-anchor {
      position: relative;
      .error-text {
        position: absolute;
        font-size: var(--font-size-s);
        background: var(--input-bg-color);
        top: -8px;
        left: -8px;
        white-space: nowrap;
        border: 1px solid var(--error-color);
        border-radius: var(--rounded-corners);
        padding: 5px;
        z-index: 99;
      }
    }

    .bank-seller-name {
      width: 100%;
      margin: 5px;
    }

    .errors.hidden {
      display: none;
    }

    .suffix {
      align-self: end;
      margin: 0 5px 5px 0;
      font-size: var(--font-size-xs);
    }

    label {
      width: 100%;
      font-size: 0.8rem;
      color: var(--input-label-color);
      margin-top: 10px;
      margin-left: var(--input-spacing-left);
      background-color: var(--input-bg-color);

      .required {
        color: var(--input-required-color);
      }
    }

    input,
    textarea {
      flex: 1;
      background-color: var(--input-bg-color);
      color: var(--input-text-color);
      font-family: var(--body-font);
      box-sizing: border-box;
      border: none;
      outline: none;
      margin: 0 0 5px var(--input-spacing-left);
      padding: 0 0 0 0;
    }

    .button-up {
      border-radius: var(--rounded-corners);
      background-color: var(--input-bg-color);
      border: 1px solid var(--button-border-color);
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .button-down {
      border-radius: var(--rounded-corners);
      background-color: var(--input-bg-color);
      border: 1px solid var(--button-border-color);
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .capital-gain-name {
      padding: 20px 0 0 10px;
      width: 50%;
    }
    .capital-gain-send {
      padding: 10px;
    }
  }

  .button-add {
    border-radius: var(--rounded-corners);
    background-color: var(--input-bg-color);
    border: 1px solid var(--button-border-color);
    font-size: var(--font-size-s);
    margin-bottom: 10px;
    width: 150px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: var(--forms-button-add-text-color);
  }

  .button-delete {
    background-image: url('../pages/Form/trash.svg');
    background-repeat: no-repeat;
    background-position: 80% 80%;
    background-color: transparent;
    height: 40px;
    width: 40px;
    border: none;
  }

  .button-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 10px;
    width: 150px;
    height: 35px;

    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    color: var(--button-text-color);
    font-size: var(--font-size-s);
    border-radius: var(--rounded-corners);

    // border-radius: var(--rounded-corners);
    // background-color: var(--input-bg-color);
    // border: 1px solid var(--button-border-color);
    // margin-top: 10px;
  }

  .button-submit-error {
    background-color: var(--error-color);
  }

  .button-submit:disabled {
    color: var(--button-disabled-text-color);
    border-color: var(--button-disabled-border-color);
  }

  .horizontal-layout-item {
    flex: 1;
  }

  .horizontal-layout {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .vertical-layout {
    display: block;
  }

  .array-item {
    width: 50%;
  }

  // .save-form {
  //   display: flex;
  //   to have info-text and save checkbox on same row
  // }
  .last-saved {
    flex: 1;
    flex-direction: row-reverse;
    text-align: right;
  }

  .ready-for-import {
    flex: 1;

    // Hide native checkbox
    input[type='checkbox'] {
      clip-path: inset(50%);
      height: 1px;
      width: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
    }

    .checkbox {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
      background-color: var(--checkbox-bg-color);
      border: none; // 1px solid var(--checkbox-border-color);
      margin: 0 10px;
      border-radius: 50%;

      svg path {
        stroke: var(--checkbox-bg-color);
      }

      &.focused {
        outline: 4px solid var(--checkbox-focus-outline-color);
      }

      &.checked {
        border-color: var(--checkbox-checked-border-color);
        background-color: var(--checkbox-checked-bg-color);

        svg path {
          stroke: var(--checkbox-checked-foreground-color);
        }
      }
    }
  }
  background-color: var(--forms-background);

  textarea {
    resize: none;
  }

  /* Applicera endast stilar på <legend> element inom .vertical-layout-item som inte innehåller .group-description */
  .vertical-layout-item:not(:has(.group-description)) legend {
    margin: 10px 0 6px 0;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 572px) {
  .forms {
    .horizontal-layout {
      flex-direction: row;
      //background-color: red;
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
