@import '~normalize.css/normalize.css';
@import 'mixins.scss';
@import 'variables.scss';
@import 'customer/demomaklaren.scss';
@import 'customer/testmaklaren.scss';
@import 'customer/demomaklaren-light.scss';
@import 'customer/demomaklaren-homepage-layout.scss';
@import 'customer/demomaklaren-new.scss';
@import 'customer/erik-olsson.scss';
@import 'customer/fastighetsmaklarna.scss';
@import 'customer/example-broker.scss';
@import 'customer/maklarringen.scss';
@import 'customer/era.scss';
@import 'customer/notar.scss';
@import 'customer/svenska-maklarhuset.scss';
@import 'customer/bjurfors.scss';
@import 'customer/skandiamaklarna.scss';
@import 'customer/hemmaklarna.scss';
@import 'estate-info.scss';
@import 'estate-data.scss';
@import 'process-nav-bar.scss';
@import 'estate-link.scss';
@import 'top-nav-bar.scss';
@import 'estate-page.scss';
@import 'process-page.scss';
@import 'bottom-nav-bar.scss';
@import 'tip.scss';
@import 'step-description.scss';
@import 'copy-to-clipboard.scss';
@import 'login.scss';
@import 'onboarding-page.scss';
@import 'fullscreen-page.scss';
@import 'list.scss';
@import 'notification.scss';
@import 'document-link.scss';
@import 'broker.scss';
@import 'checklist-link.scss';
@import 'notifications.scss';
@import 'home.scss';
@import 'profile-menu.scss';
@import 'checklist.scss';
@import 'checklist-item.scss';
@import 'viewing.scss';
@import 'loader.scss';
@import 'down-payment-status.scss';
@import 'checklists-page.scss';
@import 'event.scss';
@import 'number-info.scss';
@import 'down-payment-amount.scss';
@import 'final-price.scss';
@import 'bid-history.scss';
@import 'carousel.scss';
@import 'tax-information.scss';
@import 'collection-content.scss';
@import 'external-listing.scss';
@import 'error.scss';
@import 'views-per-channel.scss';
@import 'recommendation.scss';
@import 'impressions.scss';
@import 'price.scss';
@import 'form.scss';
@import 'add-checklist-item-page.scss';
@import 'checklist-add-link.scss';
@import 'alert.scss';
@import 'offers-page.scss';
@import 'expandable.scss';
@import 'add-bid.scss';
@import 'bidding-description.scss';
@import 'bid-list.scss';
@import 'bidding-page.scss';
@import 'loan-commitment.scss';
@import 'bidding-bid-history.scss';
@import 'leading-bid.scss';
@import 'choose-loan-commitment-bank.scss';
@import 'loan-summary.scss';
@import 'bidding-status.scss';
@import 'start-page.scss';
@import 'qr-login.scss';
@import 'personal-number-login.scss';
@import 'spinner.scss';
@import 'json-forms.scss';
@import 'json-forms-custom.scss';
@import 'form-item.scss';
@import 'form-link.scss';

body {
  position: relative;
  height: 100vh;

  -webkit-font-smoothing: subpixel-antialiased;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);

  font-family: var(--body-font);
  font-weight: 300;
  font-size: 16px; /* stylelint-disable-line */
}

h1,
h2,
h3,
h4 {
  font-family: var(--header-font);
  font-weight: var(--header-font-weight);

  &.component-heading {
    text-transform: uppercase;
    font-family: var(--body-font);
    font-weight: 300;
    color: var(--secondary-text-color);
    font-size: var(--font-size-xs);
    margin: 0;
    margin-bottom: 0.5rem;
  }

  &.page-heading {
    @extend .component-heading;
    color: var(--accent-text-color);
  }
}

p {
  line-height: 1.6rem;
}

/* stylelint-disable, declaration-no-important */
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
/* stylelint-enable, declaration-no-important */
.root-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: var(--main-bg-color);
}

main {
  padding: 0 var(--main-page-side-spacing);
}

.main-header {
  padding: var(--main-header-padding);
  margin-top: var(--top-margin);
  .header-logo {
    max-width: 200px;
  }
}
.homePage-header {
  background-image: var(--sub-header-img);
  background-color: var(--sub-header-color);

  .custom-layout {
    display: flex;
    justify-content: space-between; /* Centrerar alla element horisontellt  space-between*/
    align-items: center; /* Centrerar innehållet vertikalt */
    max-width: var(--max-content-width);
    margin: auto;
    flex: 1 0 50%;
    padding: 20px 10px 40px 10px;
    position: relative; /* Tillåter absolut positionering av barn */
    // > div:first-child {
    //     margin-right: auto; /* Flyttar den första div:en till vänster */
    // }
    .estate-pricing2 {
      color: var(--sub-header-text-color);
      margin-left: auto; /* Flyttar prissättningssektionen till höger */
      padding-top: 40px;
    }
    .empty-column {
      width: 250px;
    }
    .page-title {
      color: var(--sub-header-text-color);
      font-size: 3rem;
      font-family: var(--header-font);
    }
    .user-name {
      color: var(--sub-header-text-color);
      font-size: 1.25rem;
    }
    .estate-info {
      color: var(--sub-header-text-color);
    }
    .estateArea {
      color: var(--sub-header-text-color);
    }
    .extra-logo {
      content: var(--extra-logotype-url);
      display: block; /* Ändrar till block för att fylla tillgängligt utrymme inline-block*/
      max-width: 30%;
      height: auto; //20%;
      margin: 0 auto; /* Centrerar logotypen horisontellt */
    }
    @media screen and (max-width: 1200px) {
      .empty-column {
        visibility: hidden;
      }
    }

    @media screen and (max-width: 572px) {
      .page-title {
        font-size: 1.75rem;
        font-weight: 400;
        padding: 10px 5px 20px 0;
      }
      .user-name {
        font-size: 1rem;
        padding-top: 5px;
      }
    }
  }
}

button {
  padding: var(--container-padding-vertical) var(--container-padding-horizontal);
  border: 0;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border-radius: var(--rounded-corners);
}

.text-link {
  color: var(--text-link-color);
  text-decoration: underline;
  text-underline-offset: 1px;
}

.ie-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 50vw;
  transform: translateX(-50%) translateY(-50%);

  text-align: center;
  font-size: 1rem;
}

.content-container {
  @include content-container;
}

.logo {
  content: var(--logotype-url);
  display: inline-block;
  height: var(--logotype-height-mobile);
  padding-bottom: var(--logotype-padding-bottom);
  max-width: var(--logotype-max-width);

  @media screen and (min-width: 572px) {
    height: var(--logotype-height);
  }
}

@media screen and (min-width: 572px) {
  .main-header {
    position: relative;

    .header-logo {
      max-width: 240px;
    }
  }

  .inner-content {
    max-width: var(--max-content-width);
    margin: var(--process-step-margin);
    padding: 0 var(--tablet-content-padding);
  }

  .ie-banner {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .root-container {
    max-width: var(--max-page-width);
  }

  .inner-content {
    padding: 0;
  }

  .ie-banner {
    font-size: 2rem;
  }
}

@media screen and (max-width: 572px) {
  .inner-content {
    padding: 10px;
  }
}

.loading-line {
  min-height: calc(100vh - var(--header-size) - var(--footer-size));
}
