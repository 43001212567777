.add-checklist-item-page {
  main {
    margin-top: 20px;

    button {
      cursor: pointer;
      background-color: transparent;
      padding: 0;
      color: var(--accent-text-color);

      position: absolute;
      top: 25px;
      right: 15px;

      &:disabled {
        color: var(--button-disabled-color);
      }
    }

    .text-input-container {
      margin-bottom: 10px;
    }
  }
}
