.start-page {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    padding: calc(var(--side-spacing) * 2);
    min-height: calc(100vh - var(--header-size) - var(--footer-size));

    @media screen and (max-width: 572px) {
      min-height: calc(100vh - var(--header-size) - var(--mobile-footer-size));
    }
    header {
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
    }

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;

      button {
        background-color: var(--button-background-color);
        border: 1px solid var(--button-border-color);
        color: var(--button-text-color);
        border-radius: var(--round-corners);
        width: 100%;

        &:active {
          background-color: var(--button-active-bg-color);
        }

        &:disabled {
          background-color: var(--button-disabled-color);
        }
      }

      .site-description {
        padding: 0 10px;
        margin-top: 0;
        margin-bottom: 20px;
      }

      h1 {
        font-size: var(--font-size-l);
        margin-bottom: 30px;
      }
    }
  }
}
