.impressions-container {
  @include content-container;
  --chart-height: 300px;

  .center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .day {
    font-size: var(--font-size-xs);
  }

  .chart-container {
    height: var(--chart-height);
    margin-top: 10px;

    .label {
      color: var(--chart-legend-label-color);
    }
  }

  &.impressions-chart {
    .chart-container {
      display: flex;
      flex-direction: column;
      --label-font-size: var(--font-size-xs);
      --labels-chart-spacing: 10px;

      .chart {
        margin-top: var(--labels-chart-spacing);
        height: calc(var(--chart-height) - var(--label-font-size) - var(--labels-chart-spacing));
      }

      .labels {
        height: var(--label-font-size);

        .label {
          font-size: var(--label-font-size);
          margin-right: 10px;
          display: inline-block;

          .color {
            display: inline-block;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
