.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-link {
    margin-top: 20px;
    align-self: start;
  }

  &.login-options {
    .text-link,
    button {
      margin-top: 10px;
    }

    .login-other-device-button {
      background-color: var(--secondary-button-background-color);
      color: var(--secondary-button-text-color);
      border: 1px solid var(--secondary-button-border-color);
    }

    a:link {
      color: var(--login-video-link);
    }

    a:visited {
      color: var(--login-video-link);
    }

    a:hover {
      color: var(--login-video-link);
    }
    a:active {
      color: var(--login-video-link);
    }
  }

  &.login-remote {
    color: var(--main-text-color);
    text-align: left;

    h1 {
      text-align: center;
    }

    button[type='submit'] {
      margin-top: 10px;
    }

    .other-login-method-button {
      background-color: transparent;
      border: none;
      padding: 0;
      text-align: left;
      margin-top: 20px;
      color: var(--text-link-color);

      &:active {
        background-color: transparent;
      }
    }
  }
}
