.process-nav-bar {
  overflow-x: auto; //ta bort detta för att ta bort scrollist
  background-color: var(--process-nav-bar-fill-color);
  margin: var(--process-nav-bar-margin);

  ul {
    list-style-type: none;
    padding: 0;
    white-space: nowrap;

    li {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .current-step-marker {
    display: inline-block;
    margin-right: 8px;
    background-color: var(--current-step-marker-color);
    border: 1px solid var(--current-step-marker-border-color);
    border-radius: 100%;
    height: 0.4rem;
    width: 0.4rem;
    transform: translateY(-0.15rem);
  }

  .step {
    background-color: var(--process-nav-bar-bg-color);
    padding: 8px 20px;
    border-radius: var(--rounded-corners);
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--process-nav-bar-text-color);
    border: 1px solid var(--process-nav-bar-border-color);
    text-transform: var(--process-nav-bar-case);

    &.active {
      background-color: var(--process-nav-bar-active-bg-color);
      border-top: var(--process-nav-bar-active-border-top);
      border-right: var(--process-nav-bar-active-border-right);
      border-bottom: var(--process-nav-bar-active-border-bottom);
      border-left: var(--process-nav-bar-active-border-left);
      color: var(--process-nav-bar-active-text-color);
    }
  }

  .headerDivider {
    border-left: 1px solid #d3d3d3;
    height: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .process-nav-bar {
    ul {
      text-align: center;
    }
  }
}
