.tax-information {
  .component-heading {
    margin-bottom: 3px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
  }

  .tax-information-item {
    @include content-container;
    width: 50%;

    .title {
      font-size: var(--font-size-xs);
      color: var(--secondary-foreground-light-color);
      margin-bottom: 5px;
    }

    .info {
      font-weight: 500;
    }
  }
}
