$enable-dark-mode: true;

:root {
  --logotype-url: url('https://infoping.net/img/logo/demoblue.png');
  --primary-color: #215e7b;
  --secondary-color: #d7edf4;
  --accent-bg-color: #e6e6e6;
  --main-bg-color: #fff;
  --secondary-bg-color: #f5f5f5;
  --hpme-page-bg-color: var(--main-bg-color);
  --estate-page-bg-color: transparent;
  --estate-page-header-bg-color: transparent;

  --secondary-foreground-light-color: #575756;
  --secondary-foreground-dark-color: #1e1e1e;
  --main-text-color: #1e1e1e;
  --secondary-text-color: #575756;
  --accent-text-color: var(--primary-color);
  --number-text-color: var(--main-text-color);
  --description-bg-color: var(--secondary-bg-color);

  --text-link-color: #00b2ff;

  --carousel-active-dot-color: #ddd;

  --container-separator-color: #ddd;

  --leading-bidding-bg-color: #ddd;
  --leading-bidding-border-color: var(--leading-bidding-bg-color);
  --leading-bidding-foreground-dark-color: #1e1e1e;
  --leading-bidding-foreground-light-color: #1e1e1e;
  --leading-bidding-foreground-accent-color: #000000;

  --process-nav-bar-bg-color: var(--primary-color);
  --process-nav-bar-bar-color: transparent;
  --process-nav-bar-fill-color: transparent;
  --process-nav-bar-border-color: var(--secondary-color);
  --process-nav-bar-text-color: #ffffff;
  --process-nav-bar-active-bg-color: transparent;
  //--process-nav-bar-active-border-color: var(--primary-color); ta bort i alla css
  --process-nav-bar-active-border-top: 1px solid #ffffff;
  --process-nav-bar-active-border-right: 1px solid #ffffff;
  --process-nav-bar-active-border-bottom: 1px solid #ffffff;
  --process-nav-bar-active-border-left: 1px solid #ffffff;
  --process-nav-bar-active-text-color: var(--main-text-color);
  --process-nav-bar-case: none;
  --process-nav-bar-margin: 0;
  --process-step-margin: 0;
  --process-step-overflow: auto;
  --current-step-marker-color: var(--accent-bg-color);
  --current-step-marker-border-color: transparent;

  --top-nav-bar-padding: 0;
  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);
  --bottom-nav-bar-icon-active-contrast-color: #fff;
  --bottom-nav-bar-icon-outline-color: #1e1e1e;
  --bottom-nav-bar-icon-active-outline-color: var(--bottom-nav-bar-active-bg-color);

  --top-bar-background: transparent;

  --button-background-color: var(--primary-color);
  --button-text-color: #fff;
  --button-border-color: transparent;
  --button-active-bg-color: #9c5e00;
  --button-text-case: none;
  --secondary-button-background-color: transparent;
  --secondary-button-text-color: var(--primary-color);
  --secondary-button-border-color: var(--primary-color);

  --sp-bolan-logotype-bg-color: transparent;
  --sp-button-bg-color: #0b306d;
  --sp-button-text-color: #ffffff;
  --new-notification-circle-color: var(--primary-color);
  --notification-icon-not-read-bg-color: var(--primary-color);
  --notification-icon-not-read-foreground-color: #fff;
  --notification-icon-read-bg-color: #dddddd;
  --notification-icon-read-foreground-color: #fff;
  --notification-main-icon-color: var(--main-text-color);

  --profile-menu-button-bg-color: #fff;
  --profile-menu-button-border-color: transparent;
  --profile-menu-button-log-out-text-color: #1e1e1e;
  --profile-menu-button-cancel-text-color: var(--primary-color);
  --profile-menu-name-text-color: var(--primary-color);

  --onboarding-button-bg-color: var(--primary-color);
  --onboarding-button-border-color: transparent;
  --onboarding-button-text-color: #fff;

  --tip-bg-color: var(--main-bg-color);
  --tip-text-on-image-color: #fff;

  --leading-bid-bg-color: #e8ffea;

  --checklist-items-bg-color: #bbbbbb;
  --checklist-started-items-bg-color: var(--primary-color);
  --checklist-finished-items-bg-color: #4aaf35;
  --checklist-items-foreground-color: #1e1e1e;
  --checklist-started-items-foreground-color: #fff;
  --checklist-finished-items-foreground-color: #fff;
  --checklist-border-color: #3a3a3a;
  --checklist-finished-border-color: #3a3a3a;
  --checklist-started-border-color: #3a3a3a;

  --checkbox-bg-color: var(--main-bg-color);
  --checkbox-checked-bg-color: #215e7b;
  --checkbox-checked-foreground-color: #fff;
  --checkbox-checked-border-color: transparent;
  --checkbox-focus-outline-color: rgba(255, 199, 116, 0.5);
  --checkbox-border-color: #c5c5c5;

  --broker-icon-bg-color: #dddddd;
  --broker-icon-foreground-color: #1e1e1e;
  --broker-container-bg-color: var(--secondary-bg-color);
  --broker-image-bg-color: var(--main-bg-color);
  --broker-title-color: #000000;
  --broker-container-top-margin: 0; //behövs vid box shadow

  --button-disabled-color: #969696;
  --button-disabled-text-color: #808080;
  --button-disabled-border-color: #808080;
  --error-color: #ff0000;
  --error-alert-bg-color: #e66565;
  --success-color: #1ba000;
  --input-border-color: #dddddd;
  --input-border-focus-color: #3a3a3a;
  --input-bg-color: var(--main-bg-color);
  --input-text-color: var(--main-text-color);
  --input-label-color: var(--secondary-text-color);
  --input-required-color: var(--input-label-color);

  --header-font: serif;
  --header-font-weight: 400;
  --body-font: sans-serif;
  --font-size-l: 1.5rem;
  --font-size: 1rem;
  --font-size-s: 0.875rem;
  --font-size-xs: 0.75rem;
  --font-size-m: 1.2rem;
  --font-size-large-number: 1.5rem;
  --title-font-size: var(--font-size-m);
  --text-align: center;

  --rounded-corners: 10px;
  --round-corners: 30px;
  --side-spacing: 10px;
  --main-page-side-spacing: 10px;
  --content-spacing: 30px;
  --container-padding-horizontal: calc(var(--side-spacing) * 2);
  --container-padding-vertical: calc(var(--container-padding-horizontal) - (var(--container-padding-horizontal) / 4));
  --large-container-padding: 35px 25px 30px;

  --header-size: 110px;
  --process-nav-bar-size: 44px;
  --page-heading-size: 20px;
  --bottom-nav-bar-size: 54px;
  --tablet-content-padding: 2rem;

  --z-index-profile-menu: 1000;

  --chart-legend-label-color: var(--secondary-foreground-light-color);
  --chart-label-color: #858585;
  --chart-grid-line-color: #dfdfdf;
  --bid-history-line-color: #3a3a3a;
  --bid-history-starting-price-color: rgb(189, 189, 189);
  --chart-tooltip-bg-color: #fff;
  --chart-tooltip-foreground-color: #1e1e1e;
  --chart-tooltip-border-color: rgb(177, 177, 177);
  --chart-legend-color-box-size: 10;
  --point-border-color: var(--secondary-bg-color);
  --point-radius: 5;
  --chart-data-user-color: #8be47d;
  --chart-data-color-1: #dba14a;
  --chart-data-color-2: #dac553;
  --chart-data-color-3: #ff9900;
  --chart-data-color-4: #d44e25;
  --chart-data-color-5: #854d1f;
  --chart-data-color-6: #858d18;
  --chart-data-color-7: #ffd900;
  --total-views-color: var(--main-text-color);
  --impressions-total-data-color: transparent;
  --impressions-total-border-color: var(--primary-color);
  --impressions-broker-channel-data-color: transparent;
  --impressions-broker-channel-border-color: #d8d8d8;
  --impressions-per-channel-data-color: var(--primary-color);
  --bids-list-every-other-color: rgb(235, 235, 235);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-foreground-dark-color);

  --alert-warning-bg-color: #ffc774;
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #d44e25;
  --alert-error-text-color: #fff;
  --lowest-possible-bid-text-color: #1e1e1e;
  --top-margin: 25px;
  --logotype-height: 30px;
  --logotype-max-width: 230px;
  --logotype-height-mobile: 30px;
  --max-forms-width: 800px;
  --logotype-padding-bottom: 5px;
  --broker-image-radius: 50%;
  --broker-image-height: 90px;
  --broker-image-width: 90px;
  --broker-image-margin: 0 15px 0 0;
  --login-video-link: var(--main-text-color);
  --content-loader-color: #ced4da;
  --broker-name-case: none;
  --step-description-case: none;
  --max-page-width: 1280px;
  --max-content-width: none;
  --estate-info-starting-price-font-size: var(--font-size-xs);
  --step-description-font-weight: normal;
  --process-page-padding: 0;
  --home-page-estates-top-padding: 0;
  --forms-button-add-text-color: var(--main-text-color);
  --forms-background: var(--secondary-bg-color); // slår inte genom på andra css:er?
  --font-size-form-headers: 1.2rem;
  //måste stå nedanför det den ska räkna ut header-size mm
  --process-step-scroll-height: calc(100vh - var(--header-size) - var(--process-nav-bar-size) - var(--bottom-nav-bar-size));
  --bidding-page-scroll-height: calc(100vh - var(--header-size));
  --main-header-padding: 10px var(--side-spacing) 0;
  --sub-header-color: #ffffff;

  --img-src-estate-data: url('https://infoping.net/img/ms/demo/steps/estatedata.jpg');
  --img-src-preparations: url('https://infoping.net/img/ms/demo/steps/preparations.jpg');
  --img-src-marketing: url('https://infoping.net/img/ms/demo/steps/marketing.jpg');
  --img-src-viewing-bidding: url('https://infoping.net/img/ms/demo/steps/viewing.jpg');
  --img-src-down-payment: url('https://infoping.net/img/ms/demo/steps/downpayment.jpg');
  --img-src-contract: url('https://infoping.net/img/ms/demo/steps/contract.jpg');
  --img-src-entry: url('https://infoping.net/img/ms/demo/steps/stepentry.jpg');
  --img-src-after-entry: url('https://infoping.net/img/ms/demo/steps/stepafterentry.jpg');
  --img-src-estate-data-height: 205px;
  --img-src-preparations-height: 205px;
  --img-src-marketing-height: 205px;
  --img-src-viewing-bidding-height: 205px;
  --img-src-down-payment-height: 205px;
  --img-src-contract-height: 205px;
  --img-src-entry-height: 205px;
  --img-src-after-entry-height: 205px;
  --recommend-background-color: var(--secondary-bg-color);
}
