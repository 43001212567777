.demomaklaren-light {
  --logotype-url: url('https://infoping.net/img/logo/demoblue.png');
  --primary-color: #215e7b;
  --secondary-color: #d7edf4;
  --accent-bg-color: #e6e6e6;
  --secondary-bg-color: #f6f6f6;
  --main-text-color: #1e1e1e;
  --secondary-text-color: #575756;
  --accent-text-color: var(--primary-color);
  --header-font: 'Roobert-SemiBold', serif;
  --body-font: 'Roobert', serif;

  --process-nav-bar-bg-color: var(--primary-color);
  --process-nav-bar-border-color: var(--primary-color);
  --process-nav-bar-text-color: #fff;
  --process-nav-bar-active-bg-color: transparent;
  --process-nav-bar-active-border-color: var(--primary-color);
  --process-nav-bar-active-text-color: var(--main-text-color);
  --current-step-marker-color: var(--accent-bg-color);
  --current-step-marker-border-color: transparent;

  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);
  --bottom-nav-bar-icon-active-contrast-color: #fff;
  --bottom-nav-bar-icon-outline-color: #1e1e1e;
  --bottom-nav-bar-icon-active-outline-color: var(--bottom-nav-bar-active-bg-color);
  --lowest-possible-bid-text-color: var(--secondary-foreground-light-color);
  --button-background-color: var(--primary-color);
  --button-text-color: #fff;
  --button-border-color: transparent;
  --button-active-bg-color: #9c5e00;
  --secondary-button-background-color: transparent;
  --secondary-button-text-color: var(--primary-color);
  --secondary-button-border-color: var(--primary-color);

  --checklist-items-bg-color: #e6e6d7;
  --checklist-started-items-bg-color: var(--primary-color);
  --checklist-finished-items-bg-color: #4aaf35;
  --checklist-items-foreground-color: #1e1e1e;
  --checklist-started-items-foreground-color: #fff;
  --checklist-finished-items-foreground-color: #fff;

  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--secondary-text-color);
  --checkbox-checked-bg-color: var(--primary-color);
  --checkbox-checked-foreground-color: #fff;
  --checkbox-focus-outline-color: #aaa;

  --notification-icon-not-read-bg-color: var(--primary-color);

  --profile-menu-button-bg-color: transparent;
  --profile-menu-button-border-color: var(--main-text-color);
  --profile-menu-button-log-out-text-color: var(--main-text-color);
  --profile-menu-button-cancel-text-color: var(--main-text-color);
  --profile-menu-name-text-color: var(--primary-color);

  --broker-icon-bg-color: #e6e6d7;
  --broker-icon-foreground-color: #1e1e1e;
  --broker-container-bg-color: var(--secondary-bg-color);
  --broker-image-bg-color: var(--main-bg-color);
  --broker-title-color: #1e1e1e;

  --chart-legend-label-color: var(--secondary-foreground-light-color);
  --chart-label-color: #858585;
  --chart-grid-line-color: #dfdfdf;
  --bid-history-line-color: #3a3a3a;
  --bid-history-starting-price-color: rgb(189, 189, 189);
  --chart-tooltip-bg-color: #fff;
  --chart-tooltip-foreground-color: #1e1e1e;
  --chart-tooltip-border-color: rgb(177, 177, 177);
  --chart-legend-color-box-size: 10;
  --point-border-color: var(--secondary-bg-color);
  --point-radius: 5;
  --chart-data-color-1: #6e8564;
  --chart-data-color-2: #c3c8a5;
  --chart-data-color-3: #e6e6d7;
  --chart-data-color-4: #f5786e;
  --chart-data-color-5: #f5dcd2;
  --chart-data-color-6: #0f4641;
  --chart-data-color-7: #0f0f0f;
  --impressions-total-data-color: transparent;
  --impressions-broker-channel-data-color: transparent;
  --impressions-per-channel-data-color: var(--primary-color);
  --impressions-total-border-color: var(--primary-color);
  --impressions-broker-channel-border-color: #d8d8d8;
  --bids-list-every-other-color: rgb(235, 235, 235);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-foreground-dark-color);

  --alert-warning-bg-color: #ffc774;
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #d44e25;
  --alert-error-text-color: #fff;
  --recommend-background-color: var(--secondary-bg-color);
}
