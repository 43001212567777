.checklist-add-link {
  @include link-reset;

  .add {
    display: flex;
    align-items: center;
    margin: 0 0 var(--container-padding-vertical) 10px;
  }

  .add-text {
    font-weight: 500;
    margin-left: 10px;
  }

  .add-icon {
    stroke: var(--primary-color);
  }
}
