.text-input-container {
  position: relative;
  --input-spacing-left: 10px;

  label {
    position: absolute;
    font-size: var(--font-size-xs);
    color: var(--input-label-color);
    margin-top: 10px;
    margin-left: var(--input-spacing-left);
    background-color: var(--input-bg-color);

    .required {
      color: var(--input-required-color);
    }
  }

  input,
  textarea {
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    font-family: var(--body-font);
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    border-radius: var(--rounded-corners);
    border: 1px solid var(--input-border-color);
    padding: 20px 0 5px var(--input-spacing-left);

    &.error {
      border: 1px solid var(--error-color);
    }

    &:focus {
      outline: 1px solid var(--input-border-focus-color);
      border: none;
    }
  }

  textarea {
    height: 150px;
    padding-top: 27px;
  }
}

.error-summary-text {
  font-size: var(--font-size-s);
  color: var(--error-color);
  margin-bottom: 5px;
}
