.estate-link {
  display: flex;
  text-decoration: none;
  color: var(--main-text-color);

  img {
    width: 130px;
    min-width: 130px;
    min-height: 130px;
    @media screen and (max-width: 572px) {
      width: 100px;
      min-width: 100px;
      min-height: 100px;
    }
    border-radius: var(--rounded-corners);
    object-fit: cover;
    margin-right: var(--side-spacing);
  }

  .information {
    @include content-container;
    width: 100%;
    padding: 40px;
    @media screen and (max-width: 572px) {
      padding: 20px;
    }
  }

  .address {
    display: block;
    font-weight: 700;
    hyphens: auto;
    word-break: break-all;
  }

  .price-difference {
    margin-left: 0.2rem;
  }

  .price-text,
  .date-sold {
    font-size: var(--font-size-xs);
    color: var(--secondary-text-color);
  }

  .in-progress {
    font-size: var(--font-size-xs);
    color: var(--accent-text-color);
  }
}
