.loan-commitment {
  @include content-container;
  text-align: center;
  padding: var(--container-padding-horizontal);

  .title {
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .main-button {
    border-radius: var(--rounded-corners);
    width: 160px;
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid var(--button-border-color);
    background-color: var(--button-background-color);
  }

  .no-loan-button {
    text-decoration: underline;
    background-color: transparent;
    font-size: var(--font-size-s);
    color: var(--main-text-color);
    font-weight: inherit;
    padding: 0;
  }

  .hidden {
    display: none;
  }
}
