.estate-page {
  background-color: var(--estate-page-bg-color);
  .estate-info {
    margin: 5px 0;
    background-color: var(--estate-page-header-bg-color);
  }

  .bottom-nav-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
