@import '../styles/variables.scss';

.estate-info {
  display: flex;
  justify-content: space-between;
  max-width: 100vw;

  .address-info {
    @include link-reset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 100%;
    overflow: hidden;
  }

  .estate-address {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
    align-items: center;
    display: flex;

    flex: 0 0 100%;
    max-height: 1rem;
    margin-right: 1rem;
    padding-bottom: 2px;
  }

  .estate-area,
  .starting-price {
    font-size: var(--estate-info-starting-price-font-size);
    color: var(--secondary-text-color);
  }
  //var(--font-size-s);
  .estate-price {
    font-size: 16;
    font-weight: 700;
  }

  @media (prefers-color-scheme: dark) {
    .open-icon {
      stroke: var(--main-text-color);
      stroke-width: 0.6px;

      .box {
        stroke: var(--main-text-color);
      }
    }
  }
}
