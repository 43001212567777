.price-difference {
  font-size: var(--font-size-xs);
  color: var(--accent-text-color);
}

.price-info {
  text-align: center;

  .status {
    font-size: var(--font-size-xs);
    color: var(--secondary-foreground-light-color);
  }

  .price {
    font-size: var(--font-size-large-number);
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .extra-info {
    font-size: var(--font-size-xs);
    color: var(--secondary-foreground-light-color);
  }
}
