.down-payment-status {
  --item-spacing: 25px;

  @include content-container;
  padding: var(--item-spacing) var(--container-padding-horizontal);

  .down-payment-status-item {
    display: flex;
    justify-content: space-between;

    & + .down-payment-status-item {
      margin-top: var(--item-spacing);
    }

    div {
      margin: auto 0;

      .title {
        display: block;
        font-weight: var(--header-font-weight);
      }

      .date {
        @include content-paragraph;
      }
    }

    .checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      border: 1px solid var(--checkbox-border-color);
      background-color: var(--checkbox-bg-color);
      border-radius: 50%;

      svg {
        display: none;
      }

      &.checked {
        background-color: var(--checkbox-checked-bg-color);
        border-color: var(--checkbox-checked-border-color);

        svg {
          display: inline;

          path {
            stroke: var(--checkbox-checked-foreground-color);
          }
        }
      }
    }
  }
}
