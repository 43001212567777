.bidding-bid-history {
  @include content-container;
  --vertical-spacing: 20px;
  padding: var(--vertical-spacing) var(--container-padding-horizontal);
}

.bid-history-chart {
  .chart {
    margin-bottom: 10px;
  }

  .label {
    font-size: var(--font-size-xs);
    margin-right: 10px;
    display: inline-block;

    .color {
      display: inline-block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin-right: 5px;
    }
  }
}
