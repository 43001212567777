.down-payment-amount {
  @include content-container;
  padding: 25px var(--container-padding-horizontal);
  margin: 5px 0;

  .to-pay {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .payment-to,
  .paid-back-to {
    font-size: var(--font-size-xs);
    color: var(--secondary-foreground-light-color);
  }

  .broker-commission {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 15px 0;
  }

  .divider {
    border-top: 1px solid var(--container-separator-color);
    margin-bottom: 10px;
  }

  .amount-back {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }

  .amount-returned {
    font-weight: 700;
  }
}
