.document-link {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include content-container;

    @include link-reset;
  }

  .title {
    font-size: var(--font-size);
    font-weight: var(--header-font-weight);
  }

  svg {
    path {
      stroke: currentColor;
    }

    .arrow {
      stroke: none;
      fill: currentColor;
    }
  }
}
