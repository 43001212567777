.broker {
  display: flex;
  align-items: center;
  max-width: 100%;

  @include content-container;
  background-color: var(--broker-container-bg-color);
  box-shadow: var(--broker-container-box-shadow);
  border: 1px solid var(--broker-container-border-color);
  margin-top: var(--broker-container-top-margin);

  .content {
    overflow: hidden;
  }

  img {
    height: var(--broker-image-height);
    width: var(--broker-image-width);
    margin-right: 20px;
    border-radius: var(--broker-image-radius);
    object-fit: cover;
    background-color: var(--broker-image-bg-color);
    margin: var(--broker-image-margin);
  }

  .name {
    color: var(--broker-title-color);
    font-weight: 700;
    margin: 5px 0;
    text-transform: var(--broker-name-case);
  }

  .title {
    color: var(--broker-title-color);
    font-size: var(--font-size-s);
    margin-bottom: 5px;
  }
  .icon-lable {
    color: var(--broker-title-color);
    font-size: var(--font-size-s);
    margin-right: 5px;
  }
  .phone-icon,
  .mail-icon {
    background-color: var(--broker-icon-bg-color);
    border-radius: 50%;
    padding: 5px;
    margin: 4px 10px 4px 0;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }

  .phone-number,
  .email {
    display: flex;
    font-size: var(--font-size-s);
    align-items: center;

    svg path {
      fill: var(--broker-icon-foreground-color);
    }
  }

  a {
    text-decoration: none;
    color: var(--broker-title-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
