.estate-data {
  .buttons {
    button {
      margin-left: 10;
      margin-right: 10;
      width: 100%;
      border-radius: var(--rounded-corners);
      margin: 5px 0;
      background-color: var(--onboarding-button-bg-color);
      border: 1px solid var(--onboarding-button-border-color);
      color: var(--onboarding-button-text-color);
    }
  }

  .estateData-imagesize {
    width: 100%;
    object-fit: contain;
  }
}
