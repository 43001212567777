.external-listing {
  @include content-container;
  @include link-reset;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-info {
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
    margin-left: var(--side-spacing);
  }

  svg path {
    fill: currentColor;
  }

  .open-icon {
    .box {
      fill: none;
      stroke: currentColor;
    }
  }
}
