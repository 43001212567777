/* Standard background and border-radius for containers */
@mixin content-container {
  border-radius: var(--rounded-corners);
  background-color: var(--secondary-bg-color);
  color: var(--secondary-foreground-dark-color);
  padding: var(--container-padding-vertical) var(--container-padding-horizontal);
}

/* Removes all link effects and resets the color */
@mixin link-reset {
  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: var(--secondary-foreground-dark-color);
    text-decoration: none;
  }
}

@mixin content-paragraph {
  font-family: var(--body-font);
  color: var(--secondary-foreground-light-color);
  font-size: var(--font-size-s);
  line-height: 1.45rem;
}
