.list {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 10px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}
