@font-face {
  font-family: 'Gotham-Book';
  src: url('../../../public/fonts/era/Gotham-Book.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../../../public/fonts/era/PlayfairDisplay-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.era {
  /* box-shadow: 3px 5px rgb(0 0 0 / 0.2); skuggor?
  */
  --logotype-url: url('../../../public/images/era/ERA-Real-Estate-logo.png');
  --primary-color: #fff;
  --secondary-color: #c0c4c4;
  --accent-bg-color: #951111;
  --button-background-color: var(--accent-bg-color); /*transparent;*/
  --button-border-color: #ffffff; //var(--accent-bg-color);
  /* för den vita kanten på knapparna */
  --button-box-shadow: 0px 0px 1px 5px #951111;
  --notification-circle-color: var(--accent-bg-color);
  --accent-text-color: #feefdb;
  --header-font: 'PlayfairDisplay';
  --body-font: 'Gotham-Book';

  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);

  --main-bg-color: #2e3442; /* #303444;*/
  --main-text-color: #fff;
  --description-bg-color: var(--secondary-bg-color);
  /*951111 röd   c0c4c4  grå*/
  --secondary-bg-color: #343b4b; /* #383c4c;*/
  --secondary-text-color: #fff;
  --secondary-foreground-light-color: #ddd;
  --lowest-possible-bid-text-color: var(--secondary-foreground-light-color);
  --secondary-foreground-dark-color: var(--main-text-color);

  --secondary-button-text-color: #fff;
  --secondary-button-border-color: #fff;
  --sp-button-bg-color: var(--accent-bg-color);

  /*
  --secondary-button-background-color: #DDD;
  --secondary-button-text-color: #000;
  --secondary-button-border-color: #000;
  hej
  */
  --button-active-bg-color: #3a3a3a;
  --process-nav-bar-bg-color: var(--secondary-color);
  --process-nav-bar-border-color: var(--secondary-color);
  --process-nav-bar-text-color: #1e1e1e;
  --process-nav-bar-active-bg-color: transparent;
  --process-nav-bar-active-border-color: var(--primary-color);
  --process-nav-bar-active-text-color: var(--main-text-color);
  --current-step-marker-color: var(--accent-bg-color);
  --current-step-marker-border-color: #999;

  --checklist-items-bg-color: var(--secondary-bg-color);
  --checklist-started-items-bg-color: var(--accent-bg-color);
  --checklist-started-items-foreground-color: var(--main-text-color);
  --checklist-finished-items-bg-color: var(--secondary-bg-color);
  --checklist-finished-items-foreground-color: var(--main-text-color);
  --checklist-items-foreground-color: var(--main-text-color);
  --checklist-border-color: var(--main-text-color);
  --checklist-finished-border-color: var(--secondary-bg-color);
  --checklist-started-border-color: var(--secondary-bg-color);

  --text-link-color: var(--main-text-color);

  --tip-bg-color: var(--secondary-bg-color);

  --profile-menu-button-bg-color: transparent;
  --profile-menu-button-border-color: #fff;
  --profile-menu-button-log-out-text-color: #fff;
  --profile-menu-button-cancel-text-color: #fff;
  --profile-menu-name-text-color: #fff;

  --onboarding-button-bg-color: transparent;
  --onboarding-button-border-color: #fff;
  --onboarding-button-text-color: #fff;

  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-outline-color: #fff;
  --bottom-nav-bar-icon-active-contrast-color: var(--main-bg-color);

  --container-separator-color: var(--secondary-foreground-light-color);

  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--secondary-text-color);
  --checkbox-checked-bg-color: #666;
  --checkbox-checked-foreground-color: var(--accent-text-color);
  --checkbox-focus-outline-color: #aaa;

  --input-border-focus-color: var(--accent-text-color);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-text-color);

  --alert-warning-bg-color: var(--accent-bg-color);
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #ff6f6f;
  --alert-error-text-color: #1e1e1e;

  --chart-tooltip-bg-color: var(--secondary-bg-color);
  --chart-tooltip-foreground-color: var(--secondary-text-color);
  --chart-tooltip-border-color: rgb(236, 236, 236);
  --chart-legend-label-color: var(--secondary-foreground-light-color);
  --chart-label-color: rgb(196, 196, 196);
  --chart-grid-line-color: rgb(97, 97, 97);

  --bid-history-line-color: #fff;
  --bid-history-starting-price-color: #fff;
  --chart-data-color-1: #ccc;
  --chart-data-color-2: #999;
  --chart-data-color-3: #666;
  --chart-data-color-4: #333;
  --chart-data-color-5: #aaa;
  --chart-data-color-6: #555;
  --chart-data-color-7: #888;

  --impressions-total-data-color: var(--secondary-color);
  --impressions-broker-channel-data-color: #9c9c9c;
  --impressions-per-channel-data-color: var(--secondary-color);
  --impressions-total-border-color: var(--impressions-total-data-color);
  --impressions-broker-channel-border-color: var(--impressions-broker-channel-data-color);

  --broker-icon-bg-color: #979797;
  --broker-container-bg-color: var(--secondary-bg-color);
  --broker-icon-bg-color: #ddd;
  --broker-title-color: #var(var(--main-text-color));

  --input-border-color: #b6b6b6;
  --input-label-color: var(--secondary-text-color);
  --input-required-color: var(--input-label-color);
  --input-bg-color: var(--main-bg-color); /*rgb(70, 70, 70);*/
  --input-text-color: var(--main-text-color);
  --bids-list-every-other-color: rgb(71, 71, 71);

  --notification-icon-read-bg-color: #6d6d6d;
  --notification-icon-read-foreground-color: var(--main-text-color);
  --new-notification-circle-color: var(--accent-bg-color);
  --notification-icon-not-read-bg-color: var(--accent-bg-color);
  --notification-icon-not-read-foreground-color: #1e1e1e;

  --leading-bidding-bg-color: var(--secondary-bg-color);
  --leading-bidding-border-color: #ffffff;
  --leading-bidding-foreground-dark-color: #ffffff;
  --leading-bidding-foreground-light-color: #ffffff;
  --leading-bidding-foreground-accent-color: var(--secondary-bg-color);

  --logotype-height: 100px;
  --logotype-height-mobile: 70px;
  --broker-image-bg-color: tansparent;
  --broker-image-radius: 0%;
  --broker-image-height: 160px;
  --login-video-link: var(--main-text-color);
  --rounded-corners: 0px;
  --round-corners: 0px;
  --forms-button-add-text-color: var(--main-text-color);
  --forms-background: var(--secondary-bg-color);
  --recommend-background-color: var(--secondary-bg-color);
}
