@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  display: inline-block;
  width: 1px;
  height: 15px;
}
.loading-spinner {
  display: block;
  position: relative;
  right: 28px;
  bottom: 2px;
  width: 12px;
  height: 12px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
