.profile-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-profile-menu);

  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  transition: background-color ease-in 0.2s;

  &.visible {
    background-color: var(--main-bg-color); // rgba(0, 0, 0, 0.9);
    pointer-events: all;
    border-color: var(--main-text-color);
    border: solid;

    button {
      transform: translateX(0);
    }
  }

  ul {
    width: 100%;
    margin: 1rem;
    padding: 0;

    list-style-type: none;
  }

  .name {
    font-weight: 700;
    margin: 5px 0;
    padding: 5px;
    border-radius: 10px;
    color: var(--profile-menu-name-text-color);
  }

  button {
    width: 100%;
    border-radius: 10px;
    margin: 5px 0;

    background-color: var(--profile-menu-button-bg-color);
    border: 1px solid var(--profile-menu-button-border-color);

    transform: translateX(150%);
    transition: transform ease-in 0.4s;

    &.logout-button {
      color: var(--profile-menu-button-log-out-text-color);
    }

    &.close-button {
      color: var(--profile-menu-button-cancel-text-color);
      margin-bottom: 100px;
    }
  }
}

@media screen and (min-width: 572px) {
  .profile-menu {
    position: absolute;

    width: unset;
    height: unset;

    top: unset;
    left: unset;

    transition: none;

    &.visible {
      right: 0;
      border-radius: 10px;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.7) transparent;
        border-width: 0 10px 10px;
        top: -10px;
        left: 85%;
        margin-left: -20px;
      }
    }

    button {
      transform: none;
    }
  }
}

@media (prefers-reduced-motion) {
  .profile-menu {
    transition: none;

    button {
      transition: none;
    }
  }
}
