.form-link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include content-container;
  @include link-reset;

  .title {
    font-weight: 500;
  }

  .content {
    display: flex;
    align-items: center;
  }

  .forms {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 20px;
    min-width: 70px;
    background-color: var(--checklist-items-bg-color);
    color: var(--checklist-items-foreground-color);
    border-radius: var(--rounded-corners);
    font-size: var(--font-size-xs);
    border: 1px solid var(--checklist-border-color);
  }

  .form-submitted {
    background-color: var(--checklist-finished-items-bg-color);
    color: var(--checklist-finished-items-foreground-color);
    border: 1px solid var(--checklist-finished-border-color);
  }

  svg {
    padding-left: var(--container-padding-horizontal);

    path {
      stroke: currentColor;
    }
  }
}
