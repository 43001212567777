.home {
  padding-bottom: 10px;
  background-color: var(--home-page-bg-color);

  .home-page {
    max-width: var(--max-content-width);
    margin: var(--process-step-margin);
    padding: var(--process-page-padding);
  }

  h1.component-heading {
    margin-bottom: 40px;
  }

  .estates {
    > * {
      margin-bottom: 30px;
      padding-top: var(--home-page-estates-top-padding);
    }

    .no-estates-available {
      @include content-container;

      p {
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .home {
    --list-heading-size: calc(var(--font-size-s) + 0.5rem);

    h1.component-heading {
      margin-bottom: calc(40px + var(--list-heading-size));
    }

    > div {
      display: flex;
      gap: 20px;

      .estates {
        flex-grow: 1;

        .list:first-child {
          margin-top: calc(0px - var(--list-heading-size));
        }

        .ph-col-8 {
          flex: 0 0 80%;
        }
      }

      .ph-item {
        flex: 0 0 38%;
      }

      aside {
        flex: 0 0 38%;
        padding-top: var(--home-page-estates-top-padding);
      }
    }
  }
}
