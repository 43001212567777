.collection-content {
  --heading-to-content-spacing: 20px;

  padding-top: var(--heading-to-content-spacing);
  height: calc(100vh - var(--header-size) - var(--page-heading-size) - var(--bottom-nav-bar-size));
  overflow: auto;

  > * {
    margin-bottom: calc(var(--heading-to-content-spacing) * 2);
  }
}
