.bidding-page {
  background-color: var(--estate-page-bg-color);
  .estate-info {
    padding: var(--process-page-padding);
    margin-bottom: 10px;
    background-color: var(--main-bg-color);
    .estate-pricing {
      min-width: fit-content;
    }
  }
  .bidding-content > *,
  aside > * {
    margin-bottom: var(--content-spacing);
  }

  .bidding-content-container {
    max-width: var(--max-content-width);
    margin: var(--process-step-margin);
    padding: var(--process-page-padding);
    height: var(--bidding-page-scroll-height);
    overflow: var(--process-step-overflow);
  }

  .component-heading {
    margin-bottom: 0.5rem;
  }

  .grouped {
    > * {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .scroll-to-link {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
    text-decoration: none;
  }

  aside {
    .broker {
      display: none;
    }
  }
}

@media screen and (min-width: 572px) {
  .bidding-page {
    aside {
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;

        li {
          flex: 1 0 48%;
          margin-bottom: 0;

          .tip {
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .bidding-page {
    .bidding-content-container {
      padding: var(--process-page-padding);
      width: 100%;
      display: grid;
      grid-template-columns: 63% 35%;
      gap: 1rem;

      .bidding-content {
        max-width: 100%; // Without this the charts break the design

        .broker {
          display: none;
        }
      }

      aside {
        .broker {
          display: flex;
        }

        ul {
          display: block;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
