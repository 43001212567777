.notification {
  display: flex;
  align-items: center;

  .icon {
    background-color: var(--notification-icon-read-bg-color);
    padding: 6px;
    border-radius: 50%;

    .icon-lines {
      stroke: var(--notification-icon-read-foreground-color);
    }

    &.unread {
      background-color: var(--notification-icon-not-read-bg-color);

      .icon-lines {
        stroke: var(--notification-icon-not-read-foreground-color);
      }
    }
  }

  .content {
    margin: 10px;
    align-items: center;
  }

  .title {
    font-weight: 500;
  }

  .text {
    margin: 0;
    @include content-paragraph;
    color: var(--secondary-text-color);
    font-size: var(--font-size-xs);
    line-height: 1.2rem;
  }

  .date,
  .sender {
    font-size: var(--font-size-xs);
    color: var(--secondary-text-color);
  }
}
