.add-bid {
  @include content-container;
  padding: var(--container-padding-horizontal);

  .price-info + form {
    margin-top: 20px;
  }

  form {
    .text-input-container {
      margin-bottom: 5px;
    }

    .lowest-possible-bid {
      font-family: var(--font-body);
      font-size: var(--font-size-s);
      color: var(--lowest-possible-bid-text-color);
      display: block;
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      border-radius: var(--rounded-corners);
      background-color: var(--button-background-color);
      border: 1px solid var(--button-border-color);
    }

    .check {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .double-check {
        color: var(--error-color);
      }

      .bid-amount {
        font-size: var(--font-size-large-number);
        font-weight: var(--header-font-weight);
      }

      .buttons {
        width: 100%;
        display: flex;
        gap: 10px;
        margin-top: 10px;

        .cancel {
          background-color: transparent;
          border: 1px solid var(--button-background-color);
          color: var(--secondary-foreground-dark-color);
        }
      }

      > * {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
