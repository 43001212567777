.chart-container {
  display: flex;
  flex-direction: column;

  .description-text {
    font-size: var(--font-size-xs);
    color: var(--main-text-color);
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /*space-around om båda ska vara lika mycket på mitten*/
    width: 100%;

    .label {
      margin: 10px 0;
    }

    .square {
      width: 10px;
      height: 10px;
      display: inline-block;
    }

    .chart-cc {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 200px;
      width: 200px;
      margin-top: 30px;
      margin-left: 10%;
    }

    .chart {
      height: 100%;
      width: 100%;
    }
    .total-views {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: var(--total-views-color);
      pointer-events: none; /* Ensure the text does not interfere with the chart interactions */
    }

    .total-views-text {
      font-size: var(--font-size-xs);
      color: var(--chart-legend-label-color);
    }
  }

  .labels {
    margin-right: 20%;
  }
  .label-row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    line-height: 0.1;
  }

  .label {
    display: flex;
    align-items: center;
  }

  .square {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .label-name,
  .label-value,
  .label-percentage {
    text-align: center;
    font-size: var(--font-size-xs);
  }

  .label-name {
    display: flex; /* Lägg till flexbox */
    align-items: center; /* Vertikal centering */
    color: var(--chart-legend-label-color);
    text-align: left; /* Align label name to the left */
  }
  .label-value,
  .label-percentage {
    text-align: right;
  }
  .label-value {
    font-weight: 800;
    color: var(--main-text-color);
  }
  .info-icon {
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    height: 15px;
    width: 15px;
  }

  .tooltip {
    display: none;
    position: absolute;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9;
    width: 200px;
    white-space: pre-wrap;
    line-height: 1.5;
    opacity: 1;
    background: var(--secondary-bg-color);
    color: var(--main-text-color);
    pointer-events: none; /* Förhindrar fladder pga musen */
  }

  .label-name:hover .tooltip {
    display: block; /* Visa tooltip när .label-name hovras över */
  }
  /* För mobila enheter */
  @media only screen and (max-width: 600px) {
    .chart-cc {
      padding-right: 0;
      padding-left: 0;
    }
    .labels {
      margin-right: 0;
      padding-left: 5%;
    }
    .label-name,
    .label-value,
    .label-percentage {
      text-align: left;
      font-size: 0.7rem;
    }
    .label-row {
      gap: 5px;
      line-height: 0.9;
    }
    .tooltip {
      max-width: 100px;
    }
  }
}
