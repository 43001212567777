.qr-login {
  display: flex;
  flex-direction: column;
  width: 100%;

  .alert {
    margin-top: 0;
  }

  svg {
    width: 80%;
    align-self: center;
    margin-bottom: 20px;
  }

  .new-qr-code-timer {
    margin-bottom: 20px;
  }

  ol {
    list-style-position: inside;
    padding: 0;
    margin: 0;
  }
}
