.bid-history {
  @include content-container;
  --vertical-spacing: 20px;
  padding: var(--vertical-spacing) var(--container-padding-horizontal);

  .price-info {
    margin-bottom: var(--vertical-spacing);
  }

  .expandable {
    margin-top: 15px;
  }
}

.bid-history-chart {
  .chart {
    margin-bottom: 10px;
  }

  .label {
    font-size: var(--font-size-xs);
    margin-right: 10px;
    display: inline-block;

    .color {
      display: inline-block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin-right: 5px;
    }
  }
}

.bid-history-list {
  ol {
    border-left: 1px solid var(--secondary-foreground-light-color);
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      &:nth-child(2n - 1) {
        background-color: var(--bids-list-every-other-color);
      }

      .date {
        display: block;
        text-transform: capitalize;
      }

      .bidder-name {
        font-size: var(--font-size-xs);
        color: var(--secondary-foreground-light-color);
      }
    }
  }
}
