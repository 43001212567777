.event {
  @include content-container;
  padding: var(--large-container-padding);
  text-align: center;

  .icon {
    margin-bottom: var(--container-padding-vertical);
  }

  .push-text {
    font-weight: 500;
    margin: 5px 0;
  }

  .title {
    font-size: var(--font-size-xs);
    color: var(--secondary-foreground-light-color);
  }

  .sub-text {
    font-size: var(--font-size-xs);
    color: var(--secondary-foreground-light-color);
    a {
      color: var(--text-link-color);
    }
  }
}
