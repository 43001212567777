@font-face {
  font-family: 'Montserrat';
  src: url('../../../public/fonts/svenska-maklarhuset/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../../../public/fonts/svenska-maklarhuset/Montserrat-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../public/fonts/svenska-maklarhuset/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../public/fonts/svenska-maklarhuset/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NothingYouCouldDo';
  src: url('../../../public/fonts/svenska-maklarhuset/NothingYouCouldDo.ttf');
  font-weight: 400;
  font-style: normal;
}

.svenska-maklarhuset {
  --logotype-url: url('../../../public/images/svenska-maklarhuset/newhome_logo_color.svg');
  --primary-color: #47474a;
  --secondary-color: #dedbd5; // #e0dcd7;
  --button-background-color: var(--primary-color);
  --button-border-color: var(--primary-color);
  --button-text-color: #ffffff;
  --button-active-bg-color: var(--primary-color);
  --secondary-button-background-color: transparent;
  --secondary-button-text-color: var(--primary-color);
  --secondary-button-border-color: var(--primary-color);
  /* för den vita kanten på knapparna
  --button-box-shadow:  0px 0px 1px 5px #951111;*/
  --notification-circle-color: var(--accent-bg-color);
  --accent-text-color: #47474a;
  --header-font: 'Montserrat-Semibold';
  --body-font: 'Montserrat';
  --handwriting-font: 'NothingYouCouldDo', sans-serif;

  --bottom-nav-bar-icon-active-bg-color: var(--primary-color);
  --sp-button-bg-color: var(--primary-color);

  --main-bg-color: #ffffff;
  --main-text-color: #47474a;
  --home-page-bg-color: #f2f2f2;
  --estate-page-bg-color: #f2f2f2;
  --estate-page-header-bg-color: #ffffff;
  --accent-bg-color: #4d207a;
  --estate-info-starting-price-font-size: var(--font-size-m);
  --secondary-bg-color: #ffffff; // #e0dcd4; //#f8f8f8;
  --description-bg-color: var(--secondary-bg-color);
  --secondary-text-color: #000000;
  --secondary-foreground-light-color: var(--main-text-color);
  --secondary-foreground-dark-color: var(--main-text-color);

  --secondary-button-text-color: #47474a;
  --secondary-button-border-color: #47474a;
  --number-text-color: #4d207a;

  --button-active-bg-color: #3a3a3a;
  --process-nav-bar-bg-color: var(--main-bg-color);
  --process-nav-bar-bar-color: var(--main-bg-color);
  --process-nav-bar-fill-color: var(--main-bg-color);
  --process-nav-bar-border-color: var(--main-bg-color);
  --process-nav-bar-text-color: #1e1e1e;
  --process-nav-bar-active-bg-color: var(--main-bg-color);
  --process-nav-bar-active-border-top: transparent;
  --process-nav-bar-active-border-right: transparent;
  --process-nav-bar-active-border-bottom: 3px solid var(--accent-bg-color);
  --process-nav-bar-active-border-left: transparent;
  --process-nav-bar-active-text-color: #1e1e1e;
  --process-nav-bar-case: uppercase;
  --process-nav-bar-margin: -5px 0px 0px 0px;
  --top-nav-bar-padding: 7px 30px 7px 30px;

  --top-bar-background: var(--secondary-color);
  --current-step-marker-color: transparent;
  --current-step-marker-border-color: transparent;

  --checklist-started-items-bg-color: transparent;
  --checklist-started-items-foreground-color: #1e1e1e;
  --checklist-finished-items-bg-color: var(--main-bg-color);
  --checklist-finished-items-foreground-color: #1e1e1e;
  --checklist-items-bg-color: transparent;
  --checklist-border-color: var(--secondary-bg-color);
  --checklist-finished-border-color: var(--secondary-bg-color);
  --checklist-started-border-color: var(--secondary-bg-color);

  --text-link-color: var(--primary-color);

  --tip-bg-color: var(--secondary-bg-color); //var(--secondary-color) transparent;

  --profile-menu-button-bg-color: transparent;
  --profile-menu-button-border-color: var(--main-text-color);
  --profile-menu-button-log-out-text-color: var(--main-text-color);
  --profile-menu-button-cancel-text-color: var(--main-text-color);
  --profile-menu-name-text-color: var(--main-text-color);

  --onboarding-button-bg-color: transparent;
  --onboarding-button-border-color: var(--primary-color);
  --onboarding-button-text-color: var(--primary-color);

  --bottom-nav-bar-icon-bg-color: var(--main-bg-color);
  --bottom-nav-bar-icon-outline-color: #47474a;
  --bottom-nav-bar-icon-active-contrast-color: var(--main-bg-color);

  --container-separator-color: var(--secondary-foreground-light-color);

  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--secondary-text-color);
  --checkbox-checked-bg-color: var(--accent-bg-color);
  --checkbox-checked-foreground-color: #ffffff;
  --checkbox-focus-outline-color: #aaa;

  --input-border-focus-color: var(--accent-text-color);

  --alert-info-bg-color: var(--secondary-bg-color);
  --alert-info-text-color: var(--secondary-text-color);

  --alert-warning-bg-color: transparent;
  --alert-warning-text-color: #1e1e1e;

  --alert-error-bg-color: #ff6f6f;
  --alert-error-text-color: #1e1e1e;

  --carousel-active-dot-color: var(--primary-color);
  --chart-tooltip-bg-color: var(--secondary-bg-color);
  --chart-tooltip-foreground-color: var(--secondary-text-color);
  --chart-tooltip-border-color: #47474a;
  --chart-legend-label-color: grey;
  --chart-label-color: #666;
  --chart-grid-line-color: rgb(97, 97, 97);

  --bid-history-line-color: #47474a;
  --bid-history-starting-price-color: #47474a;
  --chart-data-user-color: var(--accent-bg-color);
  --chart-data-color-1: #4d207a;
  --chart-data-color-2: #9b84b1;
  --chart-data-color-3: #ccbbda;
  --chart-data-color-4: #cbc4bc;
  --chart-data-color-5: #eae6e1;
  --chart-data-color-6: #555;
  --chart-data-color-7: #888;
  --total-views-color: #4d207a;

  --impressions-total-data-color: var(--secondary-color);
  --impressions-total-border-color: var(--impressions-total-data-color);
  --impressions-broker-channel-data-color: #9c9c9c;
  --impressions-broker-channel-border-color: var(--impressions-broker-channel-data-color);
  --impressions-per-channel-data-color: var(--secondary-color);

  --broker-container-bg-color: #000000;
  --broker-image-bg-color: tansparent;
  --broker-image-radius: 0%;
  --broker-image-height: 150px;
  --broker-image-width: 120px;
  --broker-title-color: #dedbd5;
  --broker-image-margin: -15px 15px -15px -20px;

  --input-border-color: #b6b6b6;
  --input-label-color: var(--secondary-text-color);
  --input-required-color: var(--input-label-color);
  --input-bg-color: var(--secondary-color); //var(--main-bg-color);
  --input-text-color: var(--main-text-color);
  --bids-list-every-other-color: #f2f2f2;

  --notification-icon-read-bg-color: var(--secondary-color);
  --notification-icon-read-foreground-color: var(--main-text-color);
  --new-notification-circle-color: var(--accent-bg-color);
  --notification-icon-not-read-bg-color: var(--accent-bg-color);
  --notification-icon-not-read-foreground-color: #1e1e1e;

  --leading-bidding-bg-color: var(--secondary-bg-color);
  --leading-bidding-border-color: var(--main-bg-color);
  --leading-bidding-foreground-dark-color: var(--main-text-color);
  --leading-bidding-foreground-light-color: var(--main-text-color);
  --leading-bidding-foreground-accent-color: var(--main-text-color);
  //hide lowest possible bid text
  --lowest-possible-bid-text-color: var(--secondary-bg-color);
  --notification-icon-not-read-foreground-color: #1e1e1e;
  --logotype-height: 60px;
  --logotype-height-mobile: 40px;
  --logotype-padding-bottom: 0px;
  --top-margin: 0px;
  --rounded-corners: 1px;
  --round-corners: 5px;
  --content-loader-color: var(--home-page-bg-color);
  --broker-name-case: uppercase;
  --step-description-case: none;
  --title-font-size: 2.1rem;
  --text-align: left;
  //header och footer täcker hela sidan
  --max-content-width: 1200px;
  --max-page-width: none;
  --side-spacing: 10px;
  --main-page-side-spacing: 0px;
  // --special-margin: 0px -10px 0px -10px;
  //--special-padding: 0px 10px 0px 10px;
  --step-description-font-weight: bold;
  --process-step-margin: 20px auto 0px auto;
  --home-page-estates-top-padding: 30px;
  --process-page-padding: 10px;
  --footer-size: 400px;
  --mobile-footer-size: 200px; //för att inte hela loginsidan ska vara footer
  //dessa   tar bort den inre scrollisten på sidorna
  --process-step-overflow: 0;
  --process-step-scroll-height: none;
  --bidding-page-scroll-height: none;
  --main-header-padding: 0px;
  --large-container-padding: 25px 30px 20px;
  --img-src-estate-data: url('https://infoping.net/img/ms/smh/steps/bostaden.jpg');
  --img-src-preparations: url('https://infoping.net/img/ms/smh/steps/proffsfotografering.jpg');
  --img-src-marketing: url('https://infoping.net/img/ms/smh/steps/bostadsbeskrivning.png');
  --img-src-viewing-bidding: url('https://infoping.net/img/ms/smh/steps/visning.jpg');
  --img-src-down-payment: url('https://infoping.net/img/ms/smh/steps/handpenning.jpg');
  --img-src-entry: url('https://infoping.net/img/ms/smh/steps/tilltrade.jpg');
  --img-description-preparations-position: top;
  --img-src-contract-height: 230px;
  --recommend-background-color: var(--secondary-bg-color);
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
  //footer
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
  }

  .newhome * {
    font-family: 'Montserrat', Sans-serif;
    strong {
      font-weight: bold;
    }
    a:link {
      color: var(--secondary-text-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:visited {
      color: var(--secondary-text-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:hover {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:active {
      color: var(--secondary-text-color);
      background-color: transparent;
      text-decoration: none;
    }
  }

  footer.newhome {
    background-color: #dedbd5;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    padding: 5% 5% 0 5%;
  }
  .main-footer {
    padding-bottom: 3%;
  }

  footer.newhome section .row {
    max-width: 1140px;
    /*display: flex;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: space-between;*/
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
  }

  footer.newhome section .row .col {
    width: 33%;
    max-width: 33%;
    padding: 0 10px 10px;
  }

  footer.newhome .footer-logo {
    max-width: 100px;
    display: block;
  }

  @media (min-width: 1200px) {
    footer.newhome .footer-logo {
      max-width: 156px;
    }
  }

  footer.newhome .footer-logo img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: inline-block;
  }

  footer.newhome ul {
    padding: 0;
  }

  footer.newhome li {
    list-style: none;
    -webkit-text-size-adjust: 100%;
    line-height: 1.5;
    color: #333;
    -webkit-font-smoothing: antialiased;
    hyphens: manual;
    list-style-type: none;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
    /*display: flex;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*-webkit-box-align: start;*/
    /*align-items: flex-start;*/
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-family: 'Montserrat', Sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: calc(10px / 2);
    margin-top: calc(10px / 2);
  }

  footer.newhome li:first-child {
    margin-top: 0;
  }

  footer.newhome .icon-list-item a {
    color: #000000;
    white-space: nowrap;
  }

  footer.newhome li span.icon-list-icon {
    -webkit-text-size-adjust: 100%;
    line-height: 1.5;
    color: #333;
    -webkit-font-smoothing: antialiased;
    hyphens: manual;
    list-style-type: none;
    font-family: 'Montserrat', Sans-serif;
    font-size: 13px;
    font-weight: 400;
    box-sizing: border-box;
    /*flex-shrink: 0;*/
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 6px;
  }

  footer.newhome li span.icon-list-icon i {
    -webkit-text-size-adjust: 100%;
    hyphens: manual;
    list-style-type: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 400;
    width: 1.25em;
    color: #000000;
    font-size: 14px;
  }

  footer.newhome li span.icon-list-text {
    -ms-flex-item-align: center;
    -webkit-text-size-adjust: 100%;
    line-height: 22.5px;
    hyphens: manual;
    list-style-type: none;
    font-family: 'Montserrat', Sans-serif;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    align-self: center;
    padding-left: 5px;
    display: inline-block;
    color: #000000;
  }

  footer.newhome .grid-item a::before {
    display: none;
    text-decoration: none;
  }

  footer.newhome h4 {
    color: #000000;
    font-family: 'Montserrat', Sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    line-height: 25px;
    letter-spacing: 1px;
  }

  .newhome-footer-menu ul {
    max-height: 100%;
    /*display: flex;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*flex-direction: column;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /*justify-content: center;*/
    /* -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; */
    /*align-items: center;*/
    /* -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center; */
  }

  .newhome-footer-menu li {
    font-size: 14px;
    line-height: 22.5px;
    margin-bottom: 3px;
    padding: 0 !important;
  }

  .newhome-footer-menu li a {
    font-size: 14px;
    line-height: 22.5px;
    padding: 0 !important;
    color: #000;
  }
  .newhome-footer-menu li a:hover,
  .newhome-footer-menu li a:focus {
    background-color: transparent !important;
    color: #501e70;
  }

  footer.newhome .grid-item {
    display: inline-block;
    margin-bottom: 0;
    word-break: break-word;
    margin-right: 5px;
  }

  footer.newhome .grid-item:last-child {
    margin-right: 0;
  }

  footer.newhome .grid-item span {
    display: none;
  }

  footer.newhome .grid-item a {
    background-color: #454040;
    -webkit-border-radius: 10%;
    border-radius: 10%;
    width: 30px;
    height: 30px;
    display: block;

    transition: all 0.3s;
    display: inline-flex;
    /*-webkit-box-align: center;*/
    /*align-items: center;*/
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  footer.newhome .d-flex {
    -webkit-text-size-adjust: 100%;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    line-height: 1;
    text-align: left;
    padding: 20px 0px 10px 0px;
  }

  footer.newhome .grid-item i {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
    color: #dedbd5;
  }
  footer.newhome .grid-item:hover i {
    color: #501e70;
  }
  footer.newhome .footer-offices {
    max-width: 1140px;
    margin: auto;
  }
  footer.newhome .divider-separator {
    max-width: 1140px;
    margin: 0 auto;
    border-bottom: 1px solid #454040;
    border-width: 0 0 1px;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    height: 5px;
  }
  /*
footer.newhome .footer-offices-container {
    text-align: center;
    font-family: "Montserrat", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 0px 10px 10px 10px;
    color: #454040;

    a:link {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:visited {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:hover {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:active {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }
 Dölj alla kontor i mobilvy, den sabbade
    @media screen and (max-width: 572px) {
        visibility: hidden;
        display: none;

    }

}*/

  footer.newhome .subscribe-footer p {
    -webkit-text-size-adjust: 100%;
    hyphens: manual;
    color: #000000;
    font-family: 'Montserrat', Sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    font-style: normal;
    line-height: 22.5px;
    letter-spacing: -0.45px;
    margin-top: 0;
    margin-bottom: 0.9rem;
    box-sizing: border-box;
    a:link {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:visited {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:hover {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }

    a:active {
      color: var(--accent-bg-color);
      background-color: transparent;
      text-decoration: none;
    }
  }

  @media (max-width: 1200px) {
    .section-header-new.newhome .language-picker {
      position: absolute;
      right: 64px;
      top: 0;
      bottom: 0;
      margin: 0;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .section-header-new.newhome .nav-main,
    .section-header-new.newhome .nav-main ul {
      display: block;
      margin: 0;
    }

    .section-header-new.newhome .nav-main ul li a {
      font-size: 15px;
    }

    .section-header-new.newhome .nav-main .btn-search {
      margin: 8px 0 0 20px;
      border: 2px solid #fff;
    }

    .section-header-new.newhome.white .btn-search i,
    .page-scrolled .section-header-new.newhome .btn-search i,
    .section-header-new.newhome.white .nav-main ul li a,
    .page-scrolled .section-header-new.newhome .nav-main ul li a {
      color: #fff;
    }
  }

  @media (max-width: 995px) {
    .image-text-right .d-flex,
    .image-text-left .d-flex {
      flex-flow: column;
    }
    .container.image-text {
      margin-left: 15px;
      margin-right: 15px;
      width: auto;
    }
    .image-text .image-holder-block {
      width: 100%;
      height: 250px;
    }
    .image-text .right-block {
      width: 100%;
      padding: 30px;
    }
    .grid-colour-blocks_four .grid-colour-block,
    .grid-colour-blocks_three .grid-colour-block_three-holder,
    .grid-colour-blocks_three .grid-colour-block,
    .grid-colour-blocks_two .grid-colour-block {
      width: 100%;
    }
    .grid-colour-blocks {
      margin: 15px;
    }
    .review-slide .slide-image-holder {
      width: 50%;
    }
    .review-slide .review {
      padding: 45px;
    }
  }

  @media (max-width: 768px) {
    footer.newhome section .row {
      /*flex-wrap: wrap;*/
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    footer.newhome section .row .col {
      width: 50%;
      max-width: 50%;
    }
  }

  @media (max-width: 500px) {
    footer.newhome section .row {
      /*flex-wrap: wrap;*/
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    footer.newhome section .row .col {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
    footer.newhome section li,
    footer.newhome section .row .col .footer-logo {
      margin: 0 auto;
      /*display: flex;*/
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      /*justify-content: center;*/
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      /* word-break: break-all; */
    }
    footer.newhome .d-flex {
      text-align: center;
    }

    footer.newhome .fa-building {
      display: none;
    }
  }

  @media (max-width: 400px) {
    footer.newhome .main-footer,
    footer.newhome section .row .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
