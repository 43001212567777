.process-page {
  .process-nav-bar {
    padding: 1px 0 1px 0;
    background-color: var(--process-nav-bar-bar-color);
    /* gör att mobilvyn flyter i sidled
    margin-left: calc(var(--side-spacing) * -1);
    margin-right: calc(var(--side-spacing) * -1);*/
    margin-bottom: var(--side-spacing);
    padding-left: var(--side-spacing);
  }

  .process-step {
    padding: var(--process-page-padding);
    height: var(--process-step-scroll-height);
    overflow: var(--process-step-overflow);
    max-width: var(--max-content-width);
    margin: var(--process-step-margin);
    .process-content,
    .process-aside {
      margin-bottom: var(--content-spacing);

      > * {
        margin-bottom: var(--content-spacing);
      }
    }

    .component-heading {
      margin-bottom: 0.5rem;
    }
    .grouped {
      > * {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 572px) {
  .process-page {
    .process-step {
      padding: 0 var(--tablet-content-padding);

      .process-aside {
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 1rem;

          li {
            flex: 1 0 48%;
            margin-bottom: 0;

            .tip {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .process-page {
    .process-step {
      padding: var(--process-page-padding);
      width: 100%;
      display: grid;
      grid-template-columns: 63% 35%;
      gap: 1rem;

      .process-content {
        max-width: 100%; // Without this the charts break the design
      }

      .bank-info {
        display: flex;
        height: 3rem;
        justify-content: space-between;
        gap: 1rem;
        padding-bottom: 30px;

        > .copy-to-clipboard {
          flex-grow: 1;
        }
      }

      .process-aside {
        ul {
          display: block;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
