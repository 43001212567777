.bidding-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    font-family: var(--body-font);
    font-weight: var(--header-font-weight);
    color: var(--secondary-foreground-dark-color);
  }

  .description {
    @include content-paragraph;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
