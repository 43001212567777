.fullscreen-page {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  background-color: var(--main-bg-color);
  padding-top: 10px;
  z-index: 1;
  max-width: var(--max-forms-width);
  margin: 0 auto;

  header {
    display: grid;
    grid-template-columns: 48px 1fr 48px;
    height: 48px;
    padding: 0;

    button {
      cursor: pointer;
      background-color: transparent;
      height: 100%;
      width: 100%;
      padding: 0;

      svg {
        margin: auto;
        height: var(--font-size-s);
        width: var(--font-size-s);

        path {
          stroke: var(--main-text-color);
        }
      }
    }

    h1 {
      margin: auto;
      font-size: var(--font-size-s);
      color: var(--secondary-text-color);
      text-transform: uppercase;
    }
  }

  main {
    padding: 0 15px;
  }
}
