.copy-to-clipboard {
  .title {
    font-size: var(--font-size-xs);
    color: var(--secondary-text-color);
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    background-color: transparent;
    color: var(--main-text-color);
    font-weight: 500;

    @include content-container;
  }

  svg {
    path {
      fill: currentColor;
    }
  }
}
